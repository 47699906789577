import {useEffect} from 'react'
import Header from '../components/Home/Header';
import Category from '../components/Home/Category';
import Boutiques from '../components/Home/Boutiques';
import Banner from '../components/Home/Banner';
import Footer from '../components/Home/Footer';
import Search from '../components/Home/Search';
import Nav from '../components/Global/Nav';



const Home = () => {

  

  return (
    <div className='store-page-section'>
    <Nav />
      <Header />
      <Search />
      <Category />
      <Boutiques />
      {/* <Banner /> */}
      <Footer />
      </div>
  )
}

export default Home