import React from 'react'

const Header = () => {
  return (
    <div className='store-home-hero'> 
        <div className="store-hero" style={{ background: `url('./img/bg.png')` }}>
        <p className="store-h3">Bienvenue sur</p>
        <p className="store-h1">Diar Dzair <span>Store</span></p>
        </div>
    </div>
  )
}

export default Header