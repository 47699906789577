import { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import Loader from '../Global/Loader';




const Info = ({ step, setstep, setForm, Form, seterror }) => {

    const [EmailParrain, setEmailParrain] = useState('');
    const [nomdupropriétairedelentreprise, setnomdupropriétairedelentreprise] = useState('');
    const [Prenomdupropriétairedelentreprise, setPrenomdupropriétairedelentreprise] = useState('');

    const [Date, setDate] = useState('');
    const [Addrese, setAddrese] = useState('')
    const [Typedepiéce, setTypedepiéce] = useState('')
    const [Nif, setNif] = useState('')
    const [Nrc, setNrc] = useState('')
    const [Tva, setTva] = useState('oui')
    const [BoutiquesPlusieurs, setBoutiquesPlusieurs] = useState('oui')
    const [secteurdactivité, setsecteurdactivité] = useState('')

    const [Wilaya, setWilaya] = useState('')
    const [Wilayas, setWilayas] = useState([])

    const [Daira, setDaira] = useState('')
    const [Dairas, setDairas] = useState([])

    const [Commune, setCommune] = useState('')
    const [Communes, setCommunes] = useState([])

    const [Wilayadenaissence, setWilayadenaissence] = useState('')
    const [Wilayasdenaissence, setWilayasdenaissence] = useState([])

    const [Communedenaissence, setCommunedenaissence] = useState('')
    const [Communesdenaissence, setCommunesdenaissence] = useState([])

    const [CodePostal, setCodePostal] = useState('')
    const [CodePostals, setCodePostals] = useState([])

    // const [Nomdevosboutique, setNomdevosboutique] = useState('')

    const [attestationdimmatriculationduNIF, setattestationdimmatriculationduNIF] = useState('')
    const [copiedelapiécedidentité, setcopiedelapiécedidentité] = useState('')
    const [extraitduregistreducommerce, setextraitduregistreducommerce] = useState('')



    const [immatriculationauregistreducommerce, setimmatriculationauregistreducommerce] = useState('')



    const [Articledimposition, setArticledimposition] = useState('')

    // console.log(extraitduregistreducommerce, extraitduregistreducommerce.name, Wilaya);

    const [AllData, setAllData] = useState(new FormData())


    // useEffect(() => {

    //     const fetchData = async () => {
    //         // setLoading(true);
    //         try {
    //             const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/wilaya/`);
    //             // console.log(response[0]);
    //             setWilayas(response['wilayas']);
    //             // console.log(DarTaksitCat)

    //         } catch (error) {
    //             console.error(error.message);
    //         }
    //         // setLoading(false);
    //     }

    //     fetchData();

    // }, []);

    useEffect(() => {

        const GetCommunedenaissence = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilayadenaissence}`);


                setCommunesdenaissence(response['communes'])
                setCommunedenaissence('')

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilayadenaissence !== '') {
            GetCommunedenaissence();
        }


    }, [Wilayadenaissence])


    useEffect(() => {

        const GetDaira = async () => {
            try {

                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/dairas?valeur=${Wilaya}`);

                setDaira('')
                setDairas(response['codepostals'])

                setCommunes([])
                setCommune('')
                setCodePostals([])
                setCodePostal('')



            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilaya !== '') {
            GetDaira();
        }


    }, [Wilaya])


    useEffect(() => {

        const GetCommunes = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/commune?valeur=${Daira}`);


                setCodePostals([])
                setCodePostal('')


                setCommune('')
                setCommunes(response['codepostals'])

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Daira !== '') {
            GetCommunes();
        }


    }, [Daira])

    useEffect(() => {

        const GetCodePostal = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/codepostal?valeur=${Commune}`);
                // console.log(response);
                setCodePostal('')
                setCodePostals(response['codepostals'])





            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Commune !== '') {
            GetCodePostal();
        }


    }, [Commune])

    // console.log(getFormData(Form[0].values()));


    function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => {


            if (key === "piece_idnt" || key === "rc_file" || key === "nif_file") {

                formData.append(key, object[key]['file'], object[key]['filename'])
            } else {

                formData.append(key, object[key])
            }



        }

        );

        console.log(formData.values());
        return formData;
    }



    // console.log(AllData.values());

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({})
    // const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {


        if (nomdupropriétairedelentreprise?.toString().trim().length > 300 || nomdupropriétairedelentreprise?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, nomdupropriétairedelentreprise: 'Nom du propriétaire de l\'entreprise incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, nomdupropriétairedelentreprise: '' }))
        }

        if (Prenomdupropriétairedelentreprise?.toString().trim().length > 300 || Prenomdupropriétairedelentreprise?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Prenomdupropriétairedelentreprise: 'Prénom du propriétaire de l\'entreprise incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Prenomdupropriétairedelentreprise: '' }))
        }
        if (EmailParrain?.toString().trim().length > 300 || EmailParrain?.toString().trim() == '' || !isValidEmail(EmailParrain?.toString().trim())) {
            seterrors((prev) => ({ ...prev, EmailParrain: 'Veuillez entrer un email valide' }))
        } else {
            seterrors((prev) => ({ ...prev, EmailParrain: '' }))
        }
        if (Date?.toString().trim().length > 300 || Date?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Date: 'Date de naissance incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Date: '' }))
        }
        if (Addrese?.toString().trim().length > 300 || Addrese?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Addrese: 'Addrese incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Addrese: '' }))
        }
        if (secteurdactivité?.toString().trim().length > 300 || secteurdactivité?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, secteurdactivité: "Secteur d'activité incorrect" }))
        } else {
            seterrors((prev) => ({ ...prev, secteurdactivité: '' }))
        }
        if (Typedepiéce?.toString().trim().length > 300 || Typedepiéce?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Typedepiéce: 'Type de piéce incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Typedepiéce: '' }))
        }
        if (Nif?.toString().trim() == '' || onlyNumbers(Nif) == false || Nif?.toString().trim().length > 30) {
            seterrors((prev) => ({ ...prev, Nif: 'Nif incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Nif: '' }))
        }
        if (Nrc?.toString().trim() == '' || Nrc?.toString().trim().length > 30) {
            seterrors((prev) => ({ ...prev, Nrc: 'Nrc incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Nrc: '' }))
        }
        if (Tva?.toString().trim() == '' || (Tva?.toString().trim() !== 'oui' && Tva?.toString().trim() !== 'non')) {
            seterrors((prev) => ({ ...prev, Tva: 'Tva incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Tva: '' }))
        }
        if (BoutiquesPlusieurs?.toString().trim() == '' || (BoutiquesPlusieurs?.toString().trim() !== 'oui' && BoutiquesPlusieurs?.toString().trim() !== 'non')) {
            seterrors((prev) => ({ ...prev, BoutiquesPlusieurs: 'Champ incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, BoutiquesPlusieurs: '' }))
        }
        if (immatriculationauregistreducommerce?.toString().trim() == '' || immatriculationauregistreducommerce?.toString().trim().length > 30) {
            seterrors((prev) => ({ ...prev, immatriculationauregistreducommerce: 'immatriculation au registre du commerce incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, immatriculationauregistreducommerce: '' }))
        }

        if (Wilaya?.toString().trim() == '' || Wilaya?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Wilaya: 'Wilaya incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Wilaya: '' }))
        }
        if (Daira?.toString().trim() == '' || Daira?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Daira: 'Daira incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Daira: '' }))
        }

        if (Commune?.toString().trim() == '' || Commune?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Commune: 'Commune incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Commune: '' }))
        }

        if (CodePostal?.toString().trim() == '' || CodePostal?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, CodePostal: 'CodePostal incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, CodePostal: '' }))
        }

        if (Wilayadenaissence?.toString().trim() == '' || Wilayadenaissence?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Wilayadenaissence: 'Wilaya de naissence incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Wilayadenaissence: '' }))
        }

        if (Communedenaissence?.toString().trim() == '' || Communedenaissence?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Communedenaissence: 'Commune de naissence incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Communedenaissence: '' }))
        }
        if (Articledimposition?.toString().trim() == '' || Articledimposition?.toString().trim().length > 30 ) {
            seterrors((prev) => ({ ...prev, Articledimposition: "Article d'imposition incorrect" }))
        } else {
            seterrors((prev) => ({ ...prev, Articledimposition: '' }))
        }



        if (!attestationdimmatriculationduNIF || attestationdimmatriculationduNIF.size > 2097152) {
            seterrors((prev) => ({ ...prev, attestationdimmatriculationduNIF: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, attestationdimmatriculationduNIF: '' }))
        }

        if (!copiedelapiécedidentité || copiedelapiécedidentité.size > 2097152) {
            seterrors((prev) => ({ ...prev, copiedelapiécedidentité: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, copiedelapiécedidentité: '' }))
        }

        if (!extraitduregistreducommerce || extraitduregistreducommerce.size > 2097152) {
            seterrors((prev) => ({ ...prev, extraitduregistreducommerce: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, extraitduregistreducommerce: '' }))
        }







    }

    useEffect(() => {

        Validate();

    }, [nomdupropriétairedelentreprise, Prenomdupropriétairedelentreprise, Date, Addrese, EmailParrain, secteurdactivité, Typedepiéce, Nif, Nrc, Tva, BoutiquesPlusieurs, Wilaya, Daira, Commune, CodePostal, Wilayadenaissence, Communedenaissence, attestationdimmatriculationduNIF, copiedelapiécedidentité, immatriculationauregistreducommerce, extraitduregistreducommerce, Articledimposition])




    function GoNext() {
        // setShowerr(true)

        // console.log(FormValidate);

        // if (!FormValidate) {
        //   return
        // }


        setShowerrors(true)

        if (errors.nomdupropriétairedelentreprise !== '' || errors.Prenomdupropriétairedelentreprise !== '' || errors.Date !== '' || errors.Addrese !== '' || errors.EmailParrain !== '' || errors.secteurdactivité !== '' || errors.Typedepiéce !== '' || errors.Nif !== '' || errors.Nrc !== '' || errors.Tva !== '' || errors.BoutiquesPlusieurs !== '' || errors.Wilaya !== '' || errors.Daira !== '' || errors.Commune !== '' || errors.CodePostal !== '' || errors.Wilayadenaissence !== '' || errors.Communedenaissence !== '' || errors.attestationdimmatriculationduNIF !== '' || errors.copiedelapiécedidentité !== '' || errors.immatriculationauregistreducommerce !== '' || errors.extraitduregistreducommerce !== '' || errors.Articledimposition) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }

        console.log(AllData);



        for (var pair of AllData.entries()) {
            console.log(pair[0] + ' - ' + pair[1]);
        }

        let Data = getFormData(Form[0]);

        setLoading(true)

        console.log(Data);



        axios.post(`${process.env.REACT_APP_LOCAL_URL}/vendeur/new_vendeur`, Data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {

                // if (response.data['email'] && response.data['username'] && response.data['token']) {
                //     console.log(response.data['email'] , response.data['username'] , response.data['token']);
                //     setAuth({email : response.data['email'] , username : response.data['username'] , token :response.data['token']})
                //     setloading(false)
                //     return navigate('/')
                // }

                // console.log(response.data['creditDemandes'][0]);
                // console.log(response.data['creditDemandes'][0]['hash']);

                console.log(response.data);
                setLoading(false)

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Votre demande a été envoyée avec succès. Vous serez contacté pour terminer le processus de création de la boutique.',
                        // timer: 4000,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function (isConfirm) {
                        if (isConfirm) {
                            window.location = "/";
                        }
                    })


                    // .then(function() {
                    //     window.location = "/";
                    // });


                } else {

                    if (response.data['errorList'].constructor == Array) {
                        seterror(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });
                }


                // if (response.data['creditDemandes'][0]['hash']) {
                //   return navigate('/validation/' + Formdata[0]['hash'])

                // } else {
                //   setWrong('Il y a un problème avec les données que vous avez saisies, veuillez réessayer')
                //   console.log(response.data);
                // }

                // setloading(false)
                // console.log(response.data);

            }).catch(function (error) {
                // setLoading(false)
                setLoading(false)

                console.log(error);
            });



        // console.log('test', FormValidate);
    }

    useEffect(() => {

        setForm((prev) => [
            {
                ...prev[0], ...{
                    "email_parrain": EmailParrain,
                    "nom_prop": nomdupropriétairedelentreprise,
                    "prenom_prop": Prenomdupropriétairedelentreprise,
                    "date_naissance": Date,
                    "wilaya_naissance": Wilayadenaissence,
                    "commune_naissance": Communedenaissence,
                    "wilaya": Wilaya,
                    "dairas": Daira,
                    "commune": Commune,
                    "code_postal": CodePostal,
                    "adresse": Addrese,
                    "nin": immatriculationauregistreducommerce,
                    "nrc": Nrc,
                    "nif": Nif,
                    "article_imposition": Articledimposition,
                    "tva": Tva,
                    'type_piece': Typedepiéce,
                    "wilaya_naissance": Wilayadenaissence,
                    "commune_naissance": Communedenaissence,
                    "secteur_activite": secteurdactivité,
                    "piece_idnt": { "key": "piece_idnt", "file": copiedelapiécedidentité || null, "filename": copiedelapiécedidentité?.name || null },
                    "rc_file": { "key": "rc_file", "file": extraitduregistreducommerce || null, "filename": extraitduregistreducommerce?.name || null },
                    "nif_file": { "key": "nif_file", "file": attestationdimmatriculationduNIF || null, "filename": attestationdimmatriculationduNIF?.name || null }
                }
            }
        ])

        // setAllData(getFormData(Form[0]))

    }, [Typedepiéce, Addrese, immatriculationauregistreducommerce, Nif, Nrc, Articledimposition, Tva, secteurdactivité, EmailParrain, extraitduregistreducommerce, nomdupropriétairedelentreprise, Prenomdupropriétairedelentreprise, Date, Wilayadenaissence, Wilaya, Daira, Commune, Communedenaissence, CodePostal, copiedelapiécedidentité, attestationdimmatriculationduNIF])



    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])

    function Back() {
        setstep(1)
        window.scrollTo({ top: 400, behavior: 'smooth' })

        
    }

    return (
        <div>
            {/* {err?.length > 0 && err?.map((item, i) => (

                <div className="alert alert-danger" role="alert">
                    - {item}
                </div>

            ))
            } */}
            {Loading && 
            <Loader />
            }
            <div className={`container mt-2 ${step == 2 ? 'd-flex' : 'd-none'}`}>
                <div className='form-store'>
                    <form className="row g-5">
                        <div className="col-lg-6 mt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Parrainé par (email du parrain)</label>
                            <input type="email" maxLength={300} onChange={(e) => setEmailParrain(e.target.value.replace(/[^a-zA-Z@.0-9]+/g, ''))} value={EmailParrain} className="form-control" id="inputEmail4" />
                            <div className='err-cart-sum'>{Showerrors && errors.EmailParrain !== '' ? errors.EmailParrain : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress" className="form-label">nom du propriétaire de l'entreprise (Gérant , Directeur Général , Cadre Supérieur)</label>
                            </div>
                            <input type="text" maxLength={300} className="form-control" onChange={(e) => setnomdupropriétairedelentreprise(e.target.value)} value={nomdupropriétairedelentreprise} id="inputAddress" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.nomdupropriétairedelentreprise !== '' ? errors.nomdupropriétairedelentreprise : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress" className="form-label">Prénom du propriétaire de l'entreprise (Gérant , Directeur Général , Cadre Supérieur)</label>
                            </div>
                            <input type="text" maxLength={300} className="form-control" onChange={(e) => setPrenomdupropriétairedelentreprise(e.target.value)} value={Prenomdupropriétairedelentreprise} id="inputAddress" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.Prenomdupropriétairedelentreprise !== '' ? errors.Prenomdupropriétairedelentreprise : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress2" className="form-label">Date de naissance du propriétaire de l'entreprise</label>
                            <input type="date" className="form-control" onChange={(e) => setDate(e.target.value)} value={Date} id="inputAddress2" placeholder="Apartment, studio, or floor" />
                            <div className='err-cart-sum'>{Showerrors && errors.Date !== '' ? errors.Date : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputCity" className="form-label">secteur d'activité</label>
                            <input type="text" maxLength={300} className="form-control" onChange={(e) => setsecteurdactivité(e.target.value)} value={secteurdactivité} id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.secteurdactivité !== '' ? errors.secteurdactivité : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputCity" className="form-label">Adresse</label>
                            <input type="text" maxLength={300} className="form-control" onChange={(e) => setAddrese(e.target.value)} value={Addrese} id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.Addrese !== '' ? errors.Addrese : ''}</div>

                        </div>
                        <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Wilaya de Naissence </label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setWilayadenaissence(e.target.value)}>
                                <option disabled selected={Wilayadenaissence == "" ? "selected" : ''} value={''} >Wilaya de Naissence</option>
                                <label htmlFor="inputState" className="form-label">Wilaya de Naissence</label>
                                {WilayaList.length > 0 && WilayaList.map((item, i) => (

                                    <option value={item.value}>{item.text}</option>

                                ))
                                }

                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Wilayadenaissence !== '' ? errors.Wilayadenaissence : ''}</div>

                        </div>
                        <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Commune de Naissence</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setCommunedenaissence(e.target.value)}>
                                {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
                                <option disabled selected={Communedenaissence == "" ? "selected" : ''} value={''}>{Communesdenaissence.length > 0 ? "Commune de Naissence" : "Veuillez sélectionner La Wilaya de Naissence"}</option>
                                {/* } */}


                                {Communesdenaissence.length > 0 && Communesdenaissence.map((item, i) => (
                                    <option selected={Communedenaissence === item?.commune} value={item?.commune}>{item?.commune}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Communedenaissence !== '' ? errors.Communedenaissence : ''}</div>

                        </div>
                        <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Wilaya</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setWilaya(e.target.value)}>
                                <option disabled selected={Wilaya == "" ? "selected" : ''} value={''} >Wilaya</option>
                                <label htmlFor="inputState" className="form-label">Wilaya</label>
                                {WilayaList.length > 0 && WilayaList.map((item, i) => (
                                    <option value={item.value}>{item.text}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Wilaya !== '' ? errors.Wilaya : ''}</div>

                        </div>

                        <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Daira</label>

                            <select class="form-select" aria-label="Default select example" onChange={(e) => setDaira(e.target.value)}>
                                {/* <option disabled selected={Daira == "" ? "selected" : ''}>Daira</option> */}
                                <label htmlFor="inputState" className="form-label">Daira</label>
                                {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
                                <option disabled selected={Daira == "" ? "selected" : ''} value={''} >{ Dairas.length > 0 ? 'Daira' : 'Veuillez sélectionner une Wilaya'}</option>
                                {/* } */}


                                {Dairas.length > 0 && Dairas.map((item, i) => (
                                    <option selected={Daira === item?.dairas} value={item?.dairas}>{item?.dairas}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Daira !== '' ? errors.Daira : ''}</div>

                        </div>
                        <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Commune</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>
                                <option disabled selected={Commune == "" ? "selected" : ''} value={""} >{Communes.length > 0 ? "Commune" : "Veuillez sélectionner La Daira"}</option>
                                <label htmlFor="inputState" className="form-label">Commune</label>
                                {Communes.length > 0 && Communes.map((item, i) => (
                                    <option selected={Commune === item?.commune} value={item?.commune}>{item?.commune}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Commune !== '' ? errors.Commune : ''}</div>

                        </div>
                        <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Code Postal</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setCodePostal(e.target.value)}>
                                <option disabled selected={CodePostal == "" ? "selected" : ''} value={""} >{CodePostals.length > 0  ? 'Code Postal' : "Veuillez sélectionner La Commune"}</option>
                                <label htmlFor="inputState" className="form-label">Code Postal</label>
                                {CodePostals.length > 0 && CodePostals.map((item, i) => (
                                    <option selected={CodePostal === item?.codePostal} value={item?.codePostal}>{item?.codePostal}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.CodePostal !== '' ? errors.CodePostal : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Type de piéce d'identité du propriétaire de l'entreprise (Gérant , Directeur Général , Cadre Supérieur)</label>
                            </div>
                            <input type="text" maxLength={300} className="form-control" id="inputCity" onChange={(e) => setTypedepiéce(e.target.value)} value={Typedepiéce} />
                            <div className='err-cart-sum'>{Showerrors && errors.Typedepiéce !== '' ? errors.Typedepiéce : ''}</div>

                        </div>


                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputState" className="form-label">Numéro d'immatriculation au registre du commerce</label>
                            <input type="text" maxLength={30} onChange={(e) => setimmatriculationauregistreducommerce(e.target.value)} value={immatriculationauregistreducommerce} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.immatriculationauregistreducommerce !== '' ? errors.immatriculationauregistreducommerce : ''}</div>
                            {/* <span className='note-form'>Composé de 18 chiffres</span> */}
                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputState" className="form-label">Numéro d'identification fiscale (nif)</label>
                            <input type="tel" maxLength={30} onChange={(e) => setNif(e.target.value.replace(/[^0-9]+/g, ''))} value={Nif} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.Nif !== '' ? errors.Nif : ''}</div>
                            <span className='note-form'>Ecrire par chiffres</span>

                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputState" className="form-label">Centre national du registre de commerce NRC</label>
                            <input type="text" maxLength={30} onChange={(e) => setNrc(e.target.value)} value={Nrc} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.Nrc !== '' ? errors.Nrc : ''}</div>
                            {/* <span className='note-form'>Composé de 18 chiffres</span> */}

                        </div>
                        <div className="col-lg-6">
                            <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Veuillez télécharger une copie de l'extrait du registre du commerce </label>
                            </div>
                            <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setextraitduregistreducommerce(e.target.files[0]) : setextraitduregistreducommerce('')} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.extraitduregistreducommerce !== '' ? errors.extraitduregistreducommerce : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <div className='d-flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Veuillez télécharger une copie de la piéce d'identité du propriétaire de l'entreprise</label>
                            </div>
                            <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setcopiedelapiécedidentité(e.target.files[0]) : setcopiedelapiécedidentité('')} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.copiedelapiécedidentité !== '' ? errors.copiedelapiécedidentité : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputState" className="form-label">Télécharger l'attestation d'immatriculation du NIF</label>
                            <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setattestationdimmatriculationduNIF(e.target.files[0]) : setattestationdimmatriculationduNIF('')} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.attestationdimmatriculationduNIF !== '' ? errors.attestationdimmatriculationduNIF : ''}</div>

                        </div>
                        {/* <div className="col-lg-6">
                        <label htmlFor="inputState" className="form-label">ETES-vous assujetti à la tva ?</label>
                        <input type="text" className="form-control" id="inputCity" />
                    </div> */}
                        <div className="col-lg-6 text-center mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <div className='mb-2'>ETES-vous assujetti à la tva ?</div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1333" checked={Tva == 'oui'} onChange={(e) => setTva(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                <label className="form-check-label" htmlFor="inlineRadio1333">Oui</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2888" checked={Tva == 'non'} onChange={(e) => setTva(e.target.value)} defaultValue="non" />
                                <label className="form-check-label" htmlFor="inlineRadio2888">Non</label>
                            </div>
                            <div className='err-cart-sum'>{Showerrors && errors.Tva !== '' ? errors.Tva : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputState" className="form-label">Article d'imposition</label>
                            <input type="text" maxLength={30} className="form-control" onChange={(e) => setArticledimposition(e.target.value)} value={Articledimposition} id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.Articledimposition !== '' ? errors.Articledimposition : ''}</div>
                            {/* <span className='note-form'>Composé de 18 chiffres</span> */}

                        </div>

                        <div className="col-lg-6 text-center mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <div className='mb-2'>avez vous deja une boutique ou plusieurs boutiques sur diar dzair ?</div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio188" checked={BoutiquesPlusieurs == 'oui'} onChange={(e) => setBoutiquesPlusieurs(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                <label className="form-check-label" htmlFor="inlineRadio188">Oui</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio2999" checked={BoutiquesPlusieurs == 'non'} onChange={(e) => setBoutiquesPlusieurs(e.target.value)} defaultValue="non" />
                                <label className="form-check-label" htmlFor="inlineRadio2999">Non</label>
                            </div>
                            <div className='err-cart-sum'>{Showerrors && errors.BoutiquesPlusieurs !== '' ? errors.BoutiquesPlusieurs : ''}</div>

                        </div>
                        {/* <div className="col-md-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputState" className="form-label">veuillez indiquer le nom de vos boutique</label>
                            <input type="text" onChange={(e) => setNomdevosboutique(e.target.value)} value={Nomdevosboutique} className="form-control" id="inputCity" />
                        </div> */}
                        <div className="col-12 d-flex">
                            <div onClick={() => Back()} className="store-next-btn me-3">Retour</div>
                            <div onClick={() => GoNext()} className="store-next-btn ">Suivant</div>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    )
}

export default Info