import {useState,useEffect} from 'react'
import Header from '../components/Search/Header'
import SearchBar from '../components/Search/SearchBar'
import SearchData from '../components/Search/SearchData'
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Nav from '../components/Global/Nav';


const SearchPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const key = searchParams.get('key') ? searchParams.get('key') : '';
    const categorie = searchParams.get('categorie') ? searchParams.get('categorie') : '';
    const wilaya = searchParams.get('wilaya') ? searchParams.get('wilaya') : '';

    const [SearchKey, setSearchKey] = useState(key)

    const [Category, setCategory] = useState([])
    const [Cat, setCat] = useState(categorie)
    
    const [Wilaya, setWilaya] = useState([])
    const [WilayaS, setWilayaS] = useState(wilaya)

  return (
    <>
    <Nav />
    <div className='search-page'> 
        <Header />
        <SearchBar SearchKey={SearchKey} setSearchKey={setSearchKey} Category={Category} setCategory={setCategory} Cat={Cat} setCat={setCat} Wilaya={Wilaya} setWilaya={setWilaya} WilayaS={WilayaS} setWilayaS={setWilayaS} />
        <SearchData SearchKey={SearchKey} setSearchKey={setSearchKey} Category={Category} setCategory={setCategory} Cat={Cat} setCat={setCat} Wilaya={Wilaya} setWilaya={setWilaya} WilayaS={WilayaS} setWilayaS={setWilayaS} />
    </div>
    </>
  )
}

export default SearchPage