import { useEffect, useState } from 'react'
import axios from 'axios';

const Contact = ({Boutique}) => {
  return (
    <div className="contact-boutique-single mb-5 pb-5  mt-lg-5 pt-5">
      <div className="container  mt-lg-5 pt-5">
        <h1>Contactez Nous</h1>
        <div className="row mt-lg-0">
          <div className="col-lg-5">
            <form className="row g-3 mt-3 mt-lg-5">
              <div className="col-md-12">
                <label htmlFor="inputEmail4" className="form-label"><svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                  <g id="account" transform="translate(-469.99 -50)">
                    <path id="Tracé_9275" data-name="Tracé 9275" d="M483.362,50h1.257c.037.007.073.017.111.021.669.083,1.349.118,2.007.256a13.823,13.823,0,0,1,8.953,6.051,13.519,13.519,0,0,1,2.218,6.179c.031.288.055.576.082.864v1.257a1.667,1.667,0,0,0-.025.168,12.782,12.782,0,0,1-.332,2.341,13.877,13.877,0,0,1-5.723,8.4,13.568,13.568,0,0,1-6.428,2.385c-.288.031-.576.055-.864.082h-1.257c-.189-.018-.377-.038-.566-.053a13.556,13.556,0,0,1-3.968-.935,13.91,13.91,0,0,1-6.429-5.173,13.678,13.678,0,0,1-2.327-6.347c-.031-.287-.055-.575-.082-.863V63.371c.008-.055.021-.111.024-.167a12.784,12.784,0,0,1,.42-2.7,13.9,13.9,0,0,1,6.761-8.736,13.421,13.421,0,0,1,5.3-1.686C482.785,50.051,483.073,50.027,483.362,50ZM470.943,63.993A13.047,13.047,0,1,0,484,50.952,13.068,13.068,0,0,0,470.943,63.993Z" fill="#757575" />
                    <path id="Tracé_9276" data-name="Tracé 9276" d="M699.461,227.866a7.813,7.813,0,0,1,4.307,3.633,7.5,7.5,0,0,1,.921,3.333H703.7a6.622,6.622,0,0,0-2.778-5.087,6.512,6.512,0,0,0-4.321-1.271,6.735,6.735,0,0,0-6.377,6.357h-1a7.576,7.576,0,0,1,1.525-4.271,7.84,7.84,0,0,1,3.7-2.689,5.3,5.3,0,0,1-2.812-4.665,5.028,5.028,0,0,1,1.6-3.8,5.332,5.332,0,0,1,7.715.289,5.065,5.065,0,0,1,1.241,4.415A5.317,5.317,0,0,1,699.461,227.866Zm-2.5-8.978a4.291,4.291,0,1,0,4.321,4.288A4.306,4.306,0,0,0,696.959,218.888Z" transform="translate(-212.966 -163.1)" fill="#757575" />
                  </g>
                </svg>
                  Nom et Prénom</label>
                <input type="text" className="form-control" id="inputEmail4" />
              </div>
              <div className="col-md-12">
                <label htmlFor="inputPassword4" className="form-label"><svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                  <path id="icons8-phone" d="M9.365,6A3.374,3.374,0,0,0,6,9.365V27.635A3.374,3.374,0,0,0,9.365,31H27.635A3.374,3.374,0,0,0,31,27.635V9.365A3.374,3.374,0,0,0,27.635,6Zm0,.962H27.635a2.4,2.4,0,0,1,2.4,2.4V27.635a2.4,2.4,0,0,1-2.4,2.4H9.365a2.4,2.4,0,0,1-2.4-2.4V9.365A2.4,2.4,0,0,1,9.365,6.962Zm3.751,1.448a1.565,1.565,0,0,0-.906.361,4.4,4.4,0,0,0-1.325,1.622,5.147,5.147,0,0,0,.432,4.743.481.481,0,0,0,.049.069A22.952,22.952,0,0,0,21.756,25.609a.481.481,0,0,0,.109.085,5.147,5.147,0,0,0,4.743.433h0A4.391,4.391,0,0,0,28.23,24.8a1.58,1.58,0,0,0-.321-2.31h0l-3.236-2.184a1.548,1.548,0,0,0-1.834.08h0l-1.179.955a19.878,19.878,0,0,1-6-5.994l.963-1.19a1.545,1.545,0,0,0,.08-1.833L14.521,9.091a1.554,1.554,0,0,0-1.084-.67A1.608,1.608,0,0,0,13.117,8.409Zm.065.953a.6.6,0,0,1,.543.267l2.183,3.236h0a.573.573,0,0,1-.03.69l-1.191,1.47a.481.481,0,0,0-.036.553,20.794,20.794,0,0,0,6.779,6.776.481.481,0,0,0,.553-.037l1.46-1.182a.575.575,0,0,1,.691-.031l3.237,2.184a.6.6,0,0,1,.12.9,3.866,3.866,0,0,1-1.244,1.05,4,4,0,0,1-3.833-.343h0a.481.481,0,0,0-.109-.072A22.047,22.047,0,0,1,12.176,14.69a.481.481,0,0,0-.038-.064,4.015,4.015,0,0,1-.362-3.874,3.87,3.87,0,0,1,1.05-1.244A.623.623,0,0,1,13.181,9.363Zm-2.657,7.985a.485.485,0,0,0-.18.063l-.825.494a.481.481,0,0,0,.494.825l.825-.494a.481.481,0,0,0-.314-.888Zm1.5,2.033a.476.476,0,0,0-.357.09l-.771.574a.48.48,0,0,0,.574.771l.771-.575a.48.48,0,0,0-.217-.86Zm1.508,1.888a.482.482,0,0,0-.346.124l-.711.647a.481.481,0,1,0,.646.712l.712-.647a.481.481,0,0,0-.3-.836ZM15.221,23a.48.48,0,0,0-.333.157l-.646.712a.48.48,0,1,0,.711.646L15.6,23.8a.48.48,0,0,0-.378-.8Zm1.844,1.563a.481.481,0,0,0-.316.188l-.575.771a.481.481,0,0,0,.771.575l.575-.771a.481.481,0,0,0-.454-.762Zm2.169,1.362a.482.482,0,0,0-.48.229l-.493.824a.481.481,0,0,0,.825.494l.493-.825a.479.479,0,0,0-.346-.722Z" transform="translate(-6 -6)" fill="#757575" />
                </svg>
                  Tél</label>
                <input type="text" className="form-control" id="inputPassword4" />
              </div>
              <div className="col-md-12">
                <label htmlFor="inputEmail4" className="form-label"><svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="25" height="18.744" viewBox="0 0 25 18.744">
                  <g id="mail-142" transform="translate(126.036 94.496)">
                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(-126.036 -94.496)">
                      <path id="Tracé_9270" data-name="Tracé 9270" d="M20.985,50.283H4.015A4.02,4.02,0,0,1,0,46.268V35.555A4.02,4.02,0,0,1,4.015,31.54h16.97A4.02,4.02,0,0,1,25,35.555V46.268A4.02,4.02,0,0,1,20.985,50.283ZM4.015,32.714a2.844,2.844,0,0,0-2.841,2.841V46.268a2.844,2.844,0,0,0,2.841,2.841h16.97a2.844,2.844,0,0,0,2.841-2.841V35.555a2.844,2.844,0,0,0-2.841-2.841Z" transform="translate(0 -31.54)" fill="#757575" />
                      <path id="Tracé_9271" data-name="Tracé 9271" d="M29.276,63.214l9,8.773c.559.545,1.418-.312.858-.858l-9-8.773c-.559-.545-1.418.312-.858.858Z" transform="translate(-26.208 -59.143)" fill="#757575" />
                      <path id="Tracé_9272" data-name="Tracé 9272" d="M120.914,71.987l9-8.773c.56-.546-.3-1.4-.858-.858l-9,8.773c-.56.546.3,1.4.858.858Z" transform="translate(-107.985 -59.143)" fill="#757575" />
                      <path id="Tracé_9273" data-name="Tracé 9273" d="M156.907,128.767l-5.991-5.681c-.566-.537-1.426.319-.858.858l5.991,5.681c.566.537,1.426-.319.858-.858Z" transform="translate(-135.008 -113.853)" fill="#757575" />
                      <path id="Tracé_9274" data-name="Tracé 9274" d="M30.978,129.621l5.991-5.681c.567-.538-.291-1.395-.858-.858l-5.991,5.681c-.567.538.291,1.4.858.858Z" transform="translate(-26.966 -113.849)" fill="#757575" />
                    </g>
                  </g>
                </svg>
                  Email</label>
                <input type="email" className="form-control" id="inputEmail4" />
              </div>
              <div class="mb-3 col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label"><svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="34.5" height="17.277" viewBox="0 0 34.5 17.277">
                  <g id="Layer_1" transform="translate(-35.565 -134.6)">
                    <path id="Tracé_9277" data-name="Tracé 9277" d="M149.656,143.756c-.313-.278-.617-.548-.922-.809q-4.082-3.574-8.156-7.147a.708.708,0,0,1-.3-.609.642.642,0,0,1,.678-.591h24.2a.518.518,0,0,1,.513.5,1.532,1.532,0,0,1,0,.2v16.008a.528.528,0,0,1-.574.565H140.944a.638.638,0,0,1-.591-.417.624.624,0,0,1,.209-.73c.313-.252.643-.5.965-.739l7.921-6.069C149.517,143.869,149.578,143.817,149.656,143.756Zm-7.643-8.1c.078.07.13.113.174.156q5.008,4.382,10.008,8.773a.966.966,0,0,0,1.513,0q4.591-4.03,9.2-8.06c.322-.278.635-.556.991-.869Zm8.452,8.808c-2.765,2.122-5.513,4.226-8.3,6.356h21.581l-8.3-6.365c-.391.339-.765.678-1.139,1a1.977,1.977,0,0,1-2.7-.009C151.221,145.13,150.856,144.8,150.465,144.46Zm14.155,5.713V136.417c-2.808,2.461-5.582,4.9-8.373,7.339C159.038,145.895,161.82,148.025,164.62,150.173Z" transform="translate(-95.611 0)" fill="#757575" />
                    <path id="Tracé_9278" data-name="Tracé 9278" d="M66.257,199.443H61.692c-.087,0-.174,0-.261-.009a.519.519,0,0,1-.435-.487.509.509,0,0,1,.383-.522,1.167,1.167,0,0,1,.3-.026h9.138a1.59,1.59,0,0,1,.287.017.514.514,0,0,1-.035,1.017,2.278,2.278,0,0,1-.243.009Z" transform="translate(-23.219 -58.253)" fill="#757575" />
                    <path id="Tracé_9279" data-name="Tracé 9279" d="M40.794,232.252H36.16a.524.524,0,0,1-.513-.791.553.553,0,0,1,.522-.261h9.356a.524.524,0,0,1,.478.539.512.512,0,0,1-.513.5c-.435.009-.87.009-1.3.009Z" transform="translate(0 -88.201)" fill="#757575" />
                    <path id="Tracé_9280" data-name="Tracé 9280" d="M81.034,264.943h-3.6a.511.511,0,0,1-.548-.643.5.5,0,0,1,.426-.391,2.278,2.278,0,0,1,.243-.009h6.93a2.278,2.278,0,0,1,.243.009.517.517,0,0,1,.47.539.5.5,0,0,1-.5.487c-.348.009-.7,0-1.035,0C82.782,264.943,81.9,264.943,81.034,264.943Z" transform="translate(-37.71 -118.057)" fill="#757575" />
                    <path id="Tracé_9281" data-name="Tracé 9281" d="M94.684,166.343h-1.9a.526.526,0,0,1-.591-.513.521.521,0,0,1,.591-.53h3.817a.53.53,0,1,1,0,1.052C95.962,166.343,95.328,166.343,94.684,166.343Z" transform="translate(-51.708 -28.031)" fill="#757575" />
                    <path id="Tracé_9282" data-name="Tracé 9282" d="M103.274,296.559h1.191a.52.52,0,0,1,.583.522.508.508,0,0,1-.565.513c-.809,0-1.617.009-2.426,0a.512.512,0,0,1-.556-.522.5.5,0,0,1,.565-.513C102.465,296.551,102.865,296.559,103.274,296.559Z" transform="translate(-60.202 -147.874)" fill="#757575" />
                  </g>
                </svg>
                  Message</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>

              <div className="col-12">
                <div className="btn-boutique-sent">Envoyer</div>
              </div>
            </form>

          </div>
          <div className="col-lg-7 mt-5">
            <div>Information</div>
            <div>
              <div className="info-boutique-list mt-3">
                <div className="info-bt-items mb-3"><svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="28.756" height="36.58" viewBox="0 0 28.756 36.58">
                  <g id="location-983" transform="translate(98.434 125.532)">
                    <g id="Groupe_429" data-name="Groupe 429" transform="translate(-98.434 -125.532)">
                      <path id="Tracé_9269" data-name="Tracé 9269" d="M41.98,37.084,31.018,24.174a14.378,14.378,0,1,1,21.924,0Zm0-35.385A13.174,13.174,0,0,0,31.929,23.4L41.98,35.238,52.03,23.4A13.174,13.174,0,0,0,41.98,1.7Zm0,18.973a6.265,6.265,0,1,1,6.265-6.265A6.272,6.272,0,0,1,41.98,20.672Zm0-11.335a5.07,5.07,0,1,0,5.07,5.07A5.075,5.075,0,0,0,41.98,9.338Z" transform="translate(-27.602 -0.504)" fill="#6e6e6e" />
                    </g>
                  </g>
                </svg>

                {Boutique?.adresse}
                </div>
                <div className="info-bt-items mb-3"><svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="28.756" height="36.58" viewBox="0 0 28.756 36.58">
                  <g id="location-983" transform="translate(98.434 125.532)">
                    <g id="Groupe_429" data-name="Groupe 429" transform="translate(-98.434 -125.532)">
                      <path id="Tracé_9269" data-name="Tracé 9269" d="M41.98,37.084,31.018,24.174a14.378,14.378,0,1,1,21.924,0Zm0-35.385A13.174,13.174,0,0,0,31.929,23.4L41.98,35.238,52.03,23.4A13.174,13.174,0,0,0,41.98,1.7Zm0,18.973a6.265,6.265,0,1,1,6.265-6.265A6.272,6.272,0,0,1,41.98,20.672Zm0-11.335a5.07,5.07,0,1,0,5.07,5.07A5.075,5.075,0,0,0,41.98,9.338Z" transform="translate(-27.602 -0.504)" fill="#6e6e6e" />
                    </g>
                  </g>
                </svg>

                {Boutique?.wilaya + ' , ' + Boutique?.commune}
                </div>
                <div className="info-bt-items mb-3">
                  <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <path id="icons8-phone" d="M10.038,6A4.049,4.049,0,0,0,6,10.038V31.962A4.049,4.049,0,0,0,10.038,36H31.962A4.049,4.049,0,0,0,36,31.962V10.038A4.049,4.049,0,0,0,31.962,6Zm0,1.154H31.962a2.88,2.88,0,0,1,2.885,2.885V31.962a2.88,2.88,0,0,1-2.885,2.885H10.038a2.88,2.88,0,0,1-2.885-2.885V10.038A2.88,2.88,0,0,1,10.038,7.154Zm4.5,1.738a1.879,1.879,0,0,0-1.087.433,5.276,5.276,0,0,0-1.59,1.946,6.176,6.176,0,0,0,.518,5.691.577.577,0,0,0,.059.082A27.542,27.542,0,0,0,24.907,29.531a.577.577,0,0,0,.131.1,6.177,6.177,0,0,0,5.692.519h0a5.27,5.27,0,0,0,1.946-1.59,1.9,1.9,0,0,0-.385-2.772h0L28.406,23.17a1.857,1.857,0,0,0-2.2.1h0l-1.415,1.146a23.853,23.853,0,0,1-7.2-7.192l1.156-1.428a1.854,1.854,0,0,0,.1-2.2l-2.62-3.884a1.865,1.865,0,0,0-1.3-.8A1.929,1.929,0,0,0,14.54,8.891Zm.078,1.144a.718.718,0,0,1,.651.32l2.62,3.883h0a.687.687,0,0,1-.036.828l-1.429,1.765a.577.577,0,0,0-.043.664,24.953,24.953,0,0,0,8.134,8.131.577.577,0,0,0,.664-.044l1.752-1.419a.69.69,0,0,1,.829-.037l3.884,2.621a.725.725,0,0,1,.144,1.076,4.639,4.639,0,0,1-1.493,1.26,4.8,4.8,0,0,1-4.6-.411h0a.577.577,0,0,0-.131-.087A26.456,26.456,0,0,1,13.411,16.429a.577.577,0,0,0-.045-.077,4.818,4.818,0,0,1-.434-4.649,4.644,4.644,0,0,1,1.26-1.493A.748.748,0,0,1,14.618,10.035Zm-3.189,9.582a.582.582,0,0,0-.216.076l-.99.593a.577.577,0,0,0,.593.99l.99-.593a.577.577,0,0,0-.376-1.066Zm1.8,2.44a.571.571,0,0,0-.428.108l-.925.688a.577.577,0,0,0,.689.925l.925-.69a.576.576,0,0,0-.26-1.032Zm1.81,2.266a.579.579,0,0,0-.416.149l-.853.776a.577.577,0,1,0,.775.854l.854-.776a.577.577,0,0,0-.361-1ZM17.065,26.4a.576.576,0,0,0-.4.188l-.775.854a.576.576,0,1,0,.853.775l.776-.854a.576.576,0,0,0-.454-.963Zm2.213,1.875a.577.577,0,0,0-.38.225l-.69.925a.577.577,0,0,0,.925.69l.69-.925a.577.577,0,0,0-.545-.915Zm2.6,1.634a.579.579,0,0,0-.576.275l-.592.989a.577.577,0,0,0,.99.593l.592-.99a.575.575,0,0,0-.415-.866Z" transform="translate(-6 -6)" fill="#777" />
                  </svg>
                  {Boutique?.tel1}
                </div>
                <div className="info-bt-items mb-3">
                  <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="33.5" height="25.116" viewBox="0 0 33.5 25.116">
                    <g id="mail-142" transform="translate(126.036 94.496)">
                      <g id="Groupe_430" data-name="Groupe 430" transform="translate(-126.036 -94.496)">
                        <path id="Tracé_9270" data-name="Tracé 9270" d="M28.12,56.656H5.38A5.386,5.386,0,0,1,0,51.276V36.92a5.387,5.387,0,0,1,5.38-5.38H28.12a5.386,5.386,0,0,1,5.38,5.38V51.276A5.386,5.386,0,0,1,28.12,56.656ZM5.38,33.113A3.811,3.811,0,0,0,1.573,36.92V51.276A3.812,3.812,0,0,0,5.38,55.083H28.12a3.812,3.812,0,0,0,3.807-3.807V36.92a3.811,3.811,0,0,0-3.807-3.807Z" transform="translate(0 -31.54)" fill="#6e6e6e" />
                        <path id="Tracé_9271" data-name="Tracé 9271" d="M29.338,63.565,41.4,75.321c.75.73,1.9-.418,1.149-1.149L30.487,62.416c-.75-.731-1.9.418-1.149,1.149Z" transform="translate(-25.227 -58.109)" fill="#6e6e6e" />
                        <path id="Tracé_9272" data-name="Tracé 9272" d="M121.268,75.321l12.064-11.755c.75-.731-.4-1.88-1.149-1.149L120.118,74.171c-.75.731.4,1.88,1.149,1.149Z" transform="translate(-103.943 -58.11)" fill="#6e6e6e" />
                        <path id="Tracé_9273" data-name="Tracé 9273" d="M159.3,130.756l-8.028-7.613c-.759-.72-1.91.428-1.149,1.149l8.028,7.613c.759.719,1.91-.428,1.149-1.149Z" transform="translate(-129.955 -110.772)" fill="#6e6e6e" />
                        <path id="Tracé_9274" data-name="Tracé 9274" d="M31.333,131.9l8.028-7.613c.76-.721-.39-1.869-1.149-1.149l-8.028,7.613c-.76.721.39,1.87,1.149,1.149Z" transform="translate(-25.956 -110.768)" fill="#6e6e6e" />
                      </g>
                    </g>
                  </svg>
                  {Boutique?.email}
                </div>

                {Boutique?.map ? 

                <div className="info-bt-items mb-3"><svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="28.756" height="36.58" viewBox="0 0 28.756 36.58">
                  <g id="location-983" transform="translate(98.434 125.532)">
                    <g id="Groupe_429" data-name="Groupe 429" transform="translate(-98.434 -125.532)">
                      <path id="Tracé_9269" data-name="Tracé 9269" d="M41.98,37.084,31.018,24.174a14.378,14.378,0,1,1,21.924,0Zm0-35.385A13.174,13.174,0,0,0,31.929,23.4L41.98,35.238,52.03,23.4A13.174,13.174,0,0,0,41.98,1.7Zm0,18.973a6.265,6.265,0,1,1,6.265-6.265A6.272,6.272,0,0,1,41.98,20.672Zm0-11.335a5.07,5.07,0,1,0,5.07,5.07A5.075,5.075,0,0,0,41.98,9.338Z" transform="translate(-27.602 -0.504)" fill="#6e6e6e" />
                    </g>
                  </g>
                </svg>

                <a style={{color:'black'}} target='blank' href={Boutique?.map ? Boutique?.map : undefined}>L'emplacement est sur Google Maps</a>
                </div>
                : ''}
                
              </div>
              {/* <iframe className='mt-5' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14995555.274440683!2d-0.20800749182675665!3d26.941490557934905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fad6795639515%3A0x4ba4b4c9d0a7e602!2sAlger!5e0!3m2!1sfr!2sdz!4v1682709021364!5m2!1sfr!2sdz" width={'100%'} height={250} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
              <a target='blank' href={Boutique?.map ? Boutique?.map : undefined}>
              <div className='cart-map-store'>
                <img src='https://www.ionos.co.uk/digitalguide/fileadmin/DigitalGuide/Teaser/local-seo-t.jpg' />
              </div>
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact