import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { Routes, Route, useLocation } from "react-router-dom";
import Boutique from './pages/Boutique';
import Nav from './components/Global/Nav';
import Aide from './pages/Aide';
import CreateAccount from './pages/CreateAccount';
import MyAccount from './pages/MyAccount';
import Dashboard from './pages/DashboardUser/Dashboard';
import Listedesventes from './pages/DashboardUser/ListedesventesUser';
import Listedescommendes from './pages/DashboardUser/Listedescommendes';
import CreateVent from './pages/DashboardUser/CreateVentUser';
import VenteDetail from './pages/DashboardUser/VenteDetail';
import SearchPage from './pages/SearchPage';

import DashboardAdmin from './pages/DashboardAdmin/Dashboard';
import VentAdmin from './pages/DashboardAdmin/CreateVent';
import CreateBoutique from './pages/DashboardAdmin/CreateBoutique';
import ListedesventesAdmin from './pages/DashboardAdmin/Listedesventes';
import Product from './pages/Product';
import UpdateVent from './pages/DashboardAdmin/UpdateVent';
import Listedesboutiques from './pages/DashboardAdmin/Listedesboutique';
import UpdateBoutique from './pages/DashboardAdmin/UpdateBoutique.';
import ListVendeur from './pages/DashboardAdmin/ListVendeur';
import VendeurVoir from './pages/DashboardAdmin/VendeurVoir';
import UpdateVendeur from './pages/DashboardAdmin/UpdateVendeur';
import { Layout } from './components/Global/Layout';
import ListAdmins from './pages/DashboardAdmin/ListAdmins';
import UpdateAdmin from './pages/DashboardAdmin/UpdateAdmin';
import NewAdmin from './pages/DashboardAdmin/NewAdmin';
import LinkAdminVendeur from './pages/DashboardAdmin/LinkAdminVendeur';
import ListUsers from './pages/DashboardAdmin/ListUsers';
import CreateBoutiqueUser from './pages/DashboardUser/CreateBoutiqueUser';
import ListedesboutiquesUser from './pages/DashboardUser/ListedesboutiqueUser';
import UpdateBoutiqueUser from './pages/DashboardUser/UpdateBoutiqueUser.';
import ListedesventesUser from './pages/DashboardUser/ListedesventesUser';
import CreateVentUser from './pages/DashboardUser/CreateVentUser';
import UpdateVentUser from './pages/DashboardUser/UpdateVentUser';
import VendeurVoirUser from './pages/DashboardUser/VendeurVoirUser';
import UpdateUser from './pages/DashboardUser/UpdateUser';
import ListedesboutiquesAdmin from './pages/DashboardAdmin/ListedesboutiqueAdmin';
import ListedesventesAdminUser from './pages/DashboardAdmin/ListedesventesAdmin';
import UpdateVentesUsers from './pages/DashboardAdmin/UpdateVentesUsers';
import ForgetPassword from './pages/ForgetPassword';
import UpdatePassword from './pages/UpdatePassword';
import ProtectedRoute from './pages/ProtectedRoute';
import ListeCat from './pages/DashboardAdmin/ListCat';
import CreateCat from './pages/DashboardAdmin/CreateCat';
import UpdateCat from './pages/DashboardAdmin/UpdateCat';
import Simulation from './pages/Simulation';
import CreateQrCode from './pages/DashboardUser/CreateQrCode';
import SimulationAchats from './pages/DashboardUser/Simulation';
import FormRegister from './components/CreateAccount/FormRegister';
import Inscription from './pages/DashboardAdmin/Inscription';
import InscriptionByid from './pages/DashboardAdmin/InscriptionById';
import MakePdf from './components/MakePdf';
import AlsalamEchéanciers from './pages/DashboardAdmin/Echéanciers';
import AlsalamEchéanciersList from './pages/DashboardAdmin/EchéanciersList';




function App() {

  const location = useLocation();

  console.log(location);


  useEffect(() => {

    var nav = document.querySelector('nav'); // Identify target

    if (nav) {
      
   

    window.addEventListener('scroll', function (event) { // To listen for event
      event.preventDefault();

      if (window.scrollY <= 150) { // Just an example
        nav.style.backgroundColor = 'transparent';
      } else {
        nav.style.backgroundColor = '#3838388c';
      }
    }); 
  }

  }, [location])

  console.log(process.env.REACT_APP_LOCAL_URL);


  return (
    <>

      {/* {location.pathname !== "/account" && location.pathname !== "/dashboard" && location.pathname !== "/listventes" && location.pathname !== "/listcommendes" && location.pathname !== "/createvent" && location.pathname !== "/listventesadmin" && location.pathname !== "/vente" ? <Nav /> : ''} */}

      <Routes>

      <Route path="*" element={<Home />} />

      <Route path="/store-form" element={<FormRegister />} />
      <Route path="/makepdf" element={<MakePdf />} />


        <Route path="/" element={<Layout />}>
           
          <Route index element={<Home />} />
          <Route path="/boutique/:slug" element={<Boutique />} />
          {/* <Route path="/boutique" element={<Boutique />} /> */}
          <Route path="/aide" element={<Aide />} />
          {/* <Route path="/simulation" element={<Simulation />} /> */}
          <Route path="/createaccount" element={<CreateAccount />} />
          <Route path="/search" element={<SearchPage />} />
        <Route path="/product/:boutique/:vente" element={<Product />} />

        </Route>


          <Route path="/account" element={<MyAccount />} />
          <Route path='/forgetpassword' element={< ForgetPassword />} />
          <Route path='/updatepassword' element={< UpdatePassword />} />


        <Route element={<ProtectedRoute Role={"adminBoutique"}/>} >

        {/* Dashborad User */}
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/createboutiqueuser" element={ <CreateBoutiqueUser /> } />
        <Route path="/listboutiquesuser" element={<ListedesboutiquesUser />} />
        <Route path="/updateboutiqueuser/:id_btq" element={<UpdateBoutiqueUser />} />

        <Route path="/createvent" element={<CreateVentUser />} />
        <Route path="/createqrcode" element={<CreateQrCode />} />
        <Route path="/simulation" element={<SimulationAchats />} />


        <Route path="/listventes" element={<ListedesventesUser />} />
        <Route path="/updatevent/:id_btq/:id_vente" element={<UpdateVentUser />} />

        <Route path="/vendeur" element={<VendeurVoirUser />} />

        <Route path="/updateuser" element={<UpdateUser />} />

        <Route path="/vente" element={<VenteDetail />} />
        <Route path="/listcommendes" element={<Listedescommendes />} />
        {/* Dashborad User */}

        </Route>





        <Route element={<ProtectedRoute Role={"adminDZ"}/>} >
        {/* Dashborad Admin */}
        <Route path="/dashboardadmin" element={<DashboardAdmin />} />

        <Route path="/createventadmin" element={<VentAdmin />} />
        <Route path="/updateventadmin/:id_btq/:id_vente" element={<UpdateVent />} />
        <Route path="/updateventadminusers/:id_btq/:id_vente" element={<UpdateVentesUsers />} />


        <Route path="/createboutique" element={<CreateBoutique />} />
        <Route path="/listboutiquesadmin" element={<ListedesboutiquesAdmin />} />
        <Route path="/updateboutiqueadmin/:id_btq" element={<UpdateBoutique />} />

        <Route path="/listventesadmin" element={<ListedesventesAdmin />} />
        <Route path="/listventesuseradmin" element={<ListedesventesAdminUser />} />



        <Route path="/listadmins" element={<ListAdmins />} />
        <Route path="/newadmin" element={< NewAdmin />} />
        <Route path="/linkadminvendeur/:id" element={< LinkAdminVendeur />} />
        <Route path="/updateadmin/:id" element={<UpdateAdmin />} />

        <Route path="/listusers" element={<ListUsers />} />


        <Route path="/inscription" element={<Inscription />} />
        <Route path="/inscriptionbyid/:id" element={<InscriptionByid />} />








        <Route path="/listvendeuradmin" element={<ListVendeur />} />
        <Route path="/vendeurbyone/:id" element={<VendeurVoir />} />
        <Route path="/updatevendeur/:id" element={<UpdateVendeur />} />



        <Route path="/listboutiques" element={<Listedesboutiques />} />


        <Route path="/category" element={<ListeCat />} />
        <Route path="/createcategory" element={<CreateCat />} />
        <Route path="/updatecategory/:id" element={<UpdateCat />} />


        <Route path="/echeanciers" element={<AlsalamEchéanciers />} />
        <Route path="/echeanciersexcel" element={<AlsalamEchéanciersList />} />



        





        {/* <Route path="/vente" element={<VenteDetail />} />
    <Route path="/listcommendes" element={<Listedescommendes />} /> */}
        {/* Dashborad Admin */}
        </Route>



      </Routes>
    </>
  );
}

export default App;
