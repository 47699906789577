import {useEffect , useState,useContext} from 'react'
import LangContext from '../../Context/lang';


const Description = ({ desc, charac }) => {

  const { Lang, ProductLang, Global } = useContext(LangContext);


  // console.log(desc);
  const [Charac, setCharac] = useState(charac)
  const [Desc, setDesc] = useState(desc)
  const [TabActive, setTabActive] = useState('desc')


  useEffect(() => {
    setCharac(charac)
    setDesc(desc)

    if (desc !== null) {

      setTabActive('desc')
    }else{
      setTabActive('chara')
    }
   
  }, [desc, charac])

  // console.log(Desc !== null);
  // console.log(TabActive);
  
  return (
    
    <div className='product-desc'>
{/* <div>{TabActive}</div> */}

      { Desc !== null || Charac !== null ?
      <div className='container mt-1 pt-5 mb-5 pb-5'>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {Desc !== '' && Desc !== null && 
          <li className="nav-item" role="presentation">
            <button onClick={()=> setTabActive('desc')} className={`nav-link product-desc-btn ${Desc !== null ? 'hh8' : 'hhh8'} ${TabActive == 'desc' ? 'active' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected={`${TabActive == 'desc' ? 'true' : 'false'}`}>{(ProductLang['Description'][Lang])} <svg xmlns="http://www.w3.org/2000/svg" width="17.679" height="12.151" viewBox="0 0 17.679 12.151">
              <g id="decription" transform="translate(-476.2 -169.2)">
                <path id="Tracé_151" data-name="Tracé 151" d="M486.7,180.248v.543c.061,0,.122.008.182.008h1.259c.219,0,.343.1.345.274s-.124.278-.341.278h-6.214c-.221,0-.345-.1-.347-.27s.128-.28.357-.28h1.425v-.553h-6.236a.836.836,0,0,1-.931-.924v-9.2a.836.836,0,0,1,.931-.925h15.81a.84.84,0,0,1,.937.937v9.182a.837.837,0,0,1-.925.929H486.7Zm6.612-3.558c0-.071.008-.1.008-.136V170.1c0-.258-.1-.347-.379-.347h-15.81c-.294,0-.383.089-.383.379v9.182c0,.292.089.381.381.381h14.723c.047,0,.095-.008.2-.016-.093-.079-.148-.12-.2-.166-.347-.328-.693-.657-1.04-.985a.645.645,0,0,1-.211-.746.283.283,0,0,0-.1-.391.284.284,0,0,1-.071-.075c-.081-.122-.162-.109-.286-.041a3.442,3.442,0,0,1-3.773-.12,3.582,3.582,0,1,1,5.355-1.811c-.12.353-.1.572.187.777a.1.1,0,0,1,.024.026c.053.073.111.071.191.032a.649.649,0,0,1,.778.128Zm-1.924-2.51a3.038,3.038,0,1,0-3.041,3.029A3.041,3.041,0,0,0,491.39,174.181Zm1.934,4.308c0-.294.008-.588,0-.88a.4.4,0,0,0-.1-.235c-.207-.215-.434-.412-.645-.624-.1-.1-.168-.107-.27,0q-.539.548-1.093,1.079c-.114.11-.128.193,0,.312q.781.731,1.551,1.474a.352.352,0,0,0,.385.055.264.264,0,0,0,.18-.284Zm-7.191,1.774h-2.186v.525h2.186Zm4.635-3.414c.109.1.215.2.314.286l.521-.515-.324-.343C491.106,176.472,490.938,176.657,490.769,176.848Z" transform="translate(0 0)" fill="#fff" />
                <path id="Tracé_152" data-name="Tracé 152" d="M737.333,631.653h-8.777c-.045,0-.093,0-.138,0a.264.264,0,0,1-.249-.278.261.261,0,0,1,.245-.266.81.81,0,0,1,.138,0q4.159,0,8.317,0a.21.21,0,0,1,.241.154A3.039,3.039,0,0,0,737.333,631.653Z" transform="translate(-246.996 -452.786)" fill="#fff" />
                <path id="Tracé_153" data-name="Tracé 153" d="M733.352,239.17c-.225.176-.436.341-.651.5a.257.257,0,0,1-.144.036q-1.977,0-3.953,0a.271.271,0,0,1-.306-.276.274.274,0,0,1,.31-.274q2.347,0,4.695,0A.445.445,0,0,1,733.352,239.17Z" transform="translate(-247.124 -68.575)" fill="#fff" />
                <path id="Tracé_154" data-name="Tracé 154" d="M733.265,575.7H728.6a1.243,1.243,0,0,1-.172,0,.265.265,0,0,1-.237-.272.26.26,0,0,1,.239-.27.811.811,0,0,1,.138,0c1.277,0,2.553,0,3.83,0a.532.532,0,0,1,.274.1C732.862,575.388,733.044,575.534,733.265,575.7Z" transform="translate(-247.015 -397.94)" fill="#fff" />
                <path id="Tracé_155" data-name="Tracé 155" d="M617.711,239.972c0,.178,0,.357,0,.535a.272.272,0,0,1-.3.3c-.351,0-.7,0-1.052,0a.274.274,0,0,1-.3-.312c0-.345,0-.689,0-1.034a.273.273,0,0,1,.308-.308c.345,0,.689,0,1.034,0,.213,0,.31.1.314.322C617.713,239.64,617.711,239.806,617.711,239.972Zm-1.1.27h.531v-.523h-.531Z" transform="translate(-137.09 -68.57)" fill="#fff" />
                <path id="Tracé_156" data-name="Tracé 156" d="M616.113,351.841c0-.178,0-.357,0-.535a.267.267,0,0,1,.29-.292c.355-.006.712,0,1.068,0a.27.27,0,0,1,.3.3c0,.351,0,.7,0,1.052a.271.271,0,0,1-.3.3c-.351,0-.7,0-1.052,0a.275.275,0,0,1-.306-.312C616.109,352.185,616.113,352.013,616.113,351.841Zm.562.266h.529v-.531h-.529Z" transform="translate(-137.15 -178.223)" fill="#fff" />
                <path id="Tracé_157" data-name="Tracé 157" d="M616.884,463.061c.172,0,.345,0,.517,0a.272.272,0,0,1,.308.29c0,.355,0,.712,0,1.068a.271.271,0,0,1-.3.3c-.351,0-.7,0-1.052,0a.27.27,0,0,1-.3-.3c0-.351,0-.7,0-1.052,0-.2.1-.3.312-.3.174,0,.345,0,.519,0Zm-.268.56v.531h.533v-.531Z" transform="translate(-137.09 -288.06)" fill="#fff" />
                <path id="Tracé_158" data-name="Tracé 158" d="M616.075,575.764c0-.166,0-.333,0-.5a.276.276,0,0,1,.306-.312c.351,0,.7,0,1.052,0a.27.27,0,0,1,.3.3c0,.351,0,.7,0,1.052,0,.2-.107.3-.316.3-.345,0-.689,0-1.034,0a.275.275,0,0,1-.308-.31C616.071,576.121,616.075,575.944,616.075,575.764Zm1.089.288v-.535h-.521v.535Z" transform="translate(-137.112 -397.743)" fill="#fff" />
                <path id="Tracé_159" data-name="Tracé 159" d="M732.053,295.156c-.113.184-.205.341-.308.491a.177.177,0,0,1-.128.057q-1.563.006-3.124,0a.269.269,0,0,1-.294-.272.263.263,0,0,1,.27-.276C729.653,295.154,730.837,295.156,732.053,295.156Z" transform="translate(-247.026 -123.47)" fill="#fff" />
                <path id="Tracé_160" data-name="Tracé 160" d="M732.148,519.547c-.069,0-.112.008-.158.008h-3.331a.651.651,0,0,1-.138-.006.275.275,0,0,1-.006-.539.634.634,0,0,1,.138-.008c1.006,0,2.013,0,3.021,0a.2.2,0,0,1,.205.112C731.953,519.255,732.048,519.387,732.148,519.547Z" transform="translate(-247.124 -342.898)" fill="#fff" />
                <path id="Tracé_161" data-name="Tracé 161" d="M731.557,351.1c-.041.156-.077.312-.128.464a.146.146,0,0,1-.112.069q-1.37.006-2.741,0a.263.263,0,0,1-.276-.27.268.268,0,0,1,.286-.278c.971,0,1.942,0,2.915,0A.286.286,0,0,1,731.557,351.1Z" transform="translate(-247.124 -178.295)" fill="#fff" />
                <path id="Tracé_162" data-name="Tracé 162" d="M731.306,463.161c.051.184.1.357.156.551h-1.685c-.418,0-.839,0-1.257,0-.255,0-.4-.193-.288-.391a.321.321,0,0,1,.223-.154C729.4,463.157,730.349,463.161,731.306,463.161Z" transform="translate(-247.022 -288.16)" fill="#fff" />
                <path id="Tracé_163" data-name="Tracé 163" d="M731.014,407.108v.535c-.057,0-.108.01-.158.01-.839,0-1.677,0-2.518,0a.459.459,0,0,1-.27-.079.269.269,0,0,1,.1-.456.45.45,0,0,1,.136-.014h2.6C730.943,407.1,730.971,407.1,731.014,407.108Z" transform="translate(-246.81 -233.206)" fill="#fff" />
              </g>
            </svg>
            </button>
          </li>}
          {Charac !== '' && Charac !== null &&
          <li className="nav-item" role="presentation">
            <button onClick={()=> setTabActive('chara')} className={`nav-link product-desc-btn ${TabActive == 'chara' ? 'active': ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected={`${TabActive == 'chara' ? 'true' : false}`}>{(ProductLang['Caractéristique'][Lang])} <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
              <path id="mbrifeatures_99531" d="M11,0a.253.253,0,0,0-.229.138l-1.1,2.19-2.46.35a.247.247,0,0,0-.139.423l1.9,1.7L8.5,7.209a.252.252,0,0,0,.366.262L11,6.335l2.143,1.137a.252.252,0,0,0,.366-.261l-.471-2.405,1.885-1.7a.247.247,0,0,0-.139-.423l-2.458-.35-1.1-2.19A.253.253,0,0,0,11,0Zm0,.808.931,1.855a.25.25,0,0,0,.19.137l2.084.3L12.592,4.541a.241.241,0,0,0-.073.218l.407,2.04-1.808-.964a.256.256,0,0,0-.235,0l-1.8.963L9.5,4.759a.246.246,0,0,0-.073-.219L7.8,3.095l2.083-.3a.25.25,0,0,0,.19-.136ZM8.25,9a.754.754,0,0,0-.75.75v4.5a.754.754,0,0,0,.75.75h4.5a.754.754,0,0,0,.75-.75V9.75A.754.754,0,0,0,12.75,9Zm0,.5h4.5a.244.244,0,0,1,.25.25v4.5a.244.244,0,0,1-.25.25H8.25A.244.244,0,0,1,8,14.25V9.75A.244.244,0,0,1,8.25,9.5Zm-7.5-8A.754.754,0,0,0,0,2.25v4.5a.754.754,0,0,0,.75.75h4.5A.754.754,0,0,0,6,6.75V2.25a.754.754,0,0,0-.75-.75Zm0,.5h4.5a.244.244,0,0,1,.25.25v4.5A.244.244,0,0,1,5.25,7H.75A.244.244,0,0,1,.5,6.75V2.25A.244.244,0,0,1,.75,2Zm0,7A.754.754,0,0,0,0,9.75v4.5A.754.754,0,0,0,.75,15h4.5A.754.754,0,0,0,6,14.25V9.75A.754.754,0,0,0,5.25,9Zm0,.5h4.5a.244.244,0,0,1,.25.25v4.5a.244.244,0,0,1-.25.25H.75a.244.244,0,0,1-.25-.25V9.75A.244.244,0,0,1,.75,9.5Z" />
            </svg>
            </button>
          </li>}
        </ul>
        <div className="tab-content" id="pills-tabContent">
        {Desc !== '' && Desc !== null && 
          <div className={`tab-pane fade ${TabActive == 'desc' && 'active show'}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div
              dangerouslySetInnerHTML={{
                __html: Desc
              }}></div>

          </div>}
          {Charac !== '' && Charac !== null && 
          <div className={`tab-pane fade ${TabActive == 'chara' && 'active show'}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div
              dangerouslySetInnerHTML={{
                __html: Charac
              }}></div>

          </div>}
        </div>
      </div>
            : ''}
    </div>

  )
}

export default Description