import React, { createContext, useState, useEffect } from 'react'

const AuthContext = createContext();

export function AuthProvider({ children }) {

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  const getToken = localStorage.getItem('Token') !== '' && localStorage.getItem('Token') !== null && isJsonString(localStorage.getItem('Token')) ? JSON.parse(localStorage.getItem('Token')) : false;


  const [Auth, setAuth] = useState(getToken ? getToken : false);

  console.log(Auth);

  useEffect(() => {
    localStorage.setItem('Token', JSON.stringify(Auth));
  }, [Auth]);










  return <AuthContext.Provider value={{ Auth, setAuth }}>{children}</AuthContext.Provider>

}


export default AuthContext