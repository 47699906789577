import { useEffect, useState } from 'react'
import axios from 'axios';
import Loader from '../Global/Loader';
import Swal from 'sweetalert2'

const FormRegister = () => {
    const [error, seterror] = useState([]);


    console.log(error);


    const [NometPrenom, setNometPrenom] = useState('');
    const [secteuActivité, setsecteuActivité] = useState('');
    const [Tel, setTel] = useState('')

    const [Lang, setLang] = useState("ar")


    const [Wilaya, setWilaya] = useState('')

    const [Commune, setCommune] = useState('')
    const [Communesdenaissence, setCommunesdenaissence] = useState([])

    useEffect(() => {

        const GetCommune = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilaya}`);


                setCommunesdenaissence(response['communes'])
                setCommune('')

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilaya !== '') {
            GetCommune();
        }


    }, [Wilaya])





    function GoNext() {

        setShowerrors(true)

        if (errors.NometPrenom[Lang] !== '' || errors.secteuActivité[Lang] !== '' || errors.Tel[Lang] !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");
            for (let index = 0; index < matches.length; index++) {
                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();
                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }
            return;
        }

        const SentForm = async () => {
            setLoading(true)
            try {
                const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/reclamations/form-register`, { "nom": NometPrenom, "wilaya": Wilaya, "commune": Commune, "tel": Tel, "secteur_activite": secteuActivité }, {
                    // headers: {
                    //   'Content-Type': 'application/x-www-form-urlencoded'
                    // }
                });

                // console.log(response['errorCode']);
                console.log(response);
                if (response['error'] == false) {
                    // console.log(response.data['err']);
                    Swal.fire({
                        title:  Lang === "fr" ? 'Succès' : 'تم الإرسال',
                        type: 'success',
                        text: Lang === "fr" ? 'Votre demande a été envoyée avec succès. Nous vous contacterons bientôt' : 'تم إرسال طلبك بنجاح, سيتم التواصل معك قريبا',
                        // timer: 4000,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function (isConfirm) {
                        if (isConfirm) {
                            window.location = "https://new.diardzair.com.dz/assistence";
                        }
                    })


                    // .then(function() {
                    //     window.location = "/";
                    // });


                } else {

                    if (response['errorList'].constructor == Array) {
                        seterror(response['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text:  Lang === "fr" ? "Il y a un problème lors de l'ajout de données." : "هناك مشكل في إرسال طلبك يرجى المحاولة مرة أخرى",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });
                }

                setLoading(false)
                // localStorage.removeItem('cart')



            } catch (error) {
                setLoading(false)
                // console.error(error.message);
            }
        }

        SentForm();



    }

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Titreduproduit: { "fr": '', "ar": "" }, Réferenceduproduit: { "fr": '', "ar": "" }, Marque: { "fr": '', "ar": "" } })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        if (NometPrenom?.toString().trim().length > 300 || NometPrenom?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, NometPrenom: { "fr": 'Veuillez écrire votre nom et prénom', "ar": "يرجى كتابة الإسم واللقب" } }))
        } else {
            seterrors((prev) => ({ ...prev, NometPrenom: { "fr": '', "ar": "" } }))
        }

        if (Tel?.toString().trim().length > 12 || Tel?.toString().trim().length < 9 || Tel?.toString().trim() == '' || !onlyNumbers(Tel)) {
            seterrors((prev) => ({ ...prev, Tel: { "fr": "Veuillez écrire un numéro de téléphone valide (Max 12) (Min 9)", "ar": "(Max 12) (Min 9) - يرجى كتابة رقم هاتف صحيح" } }))
        } else {
            seterrors((prev) => ({ ...prev, Tel: { "fr": '', "ar": "" } }))
        }

        if (secteuActivité?.toString().trim().length > 300 || secteuActivité?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, secteuActivité: { "fr": "Veuillez écrire le domaine d'activité", "ar": "برجى تحديد قطاع النشاط" } }))
        } else {
            seterrors((prev) => ({ ...prev, secteuActivité: { "fr": '', "ar": "" } }))
        }

        if (Wilaya?.toString().trim() == '' || Wilaya?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Wilaya: { "fr": 'Veuillez sélectionner La Wilaya ', "ar": "يرجى تحديد الولاية" } }))
        } else {
            seterrors((prev) => ({ ...prev, Wilaya: { "fr": '', "ar": "" } }))
        }

        if (Commune?.toString().trim() == '' || Commune?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Commune: { "fr": 'Veuillez sélectionner La Commune', "ar": "يرجى تحديد البلدية" } }))
        } else {
            seterrors((prev) => ({ ...prev, Commune: { "fr": '', "ar": "" } }))
        }


    }

    useEffect(() => {

        Validate();

    }, [NometPrenom, Tel, secteuActivité, Wilaya, Commune])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");
            for (let index = 0; index < matches.length; index++) {
                if (matches[index].textContent.trim() !== '') {
                    let position = matches[index].getBoundingClientRect();
                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }
        }
    }, [Showerrors])



    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];


    const Label = {
        "name": { 'ar': "الإسم واللقب", 'fr': "Nom et Prénom" }, "Mobile": { 'ar': "رقم الهاتف", 'fr': "Numéro téléphone" }, "wilaya": { "ar": "الولاية", "fr": "Wilaya" }
        , "commune": { "ar": "البلدية", "fr": "Commune" }, "secteur": { "ar": "قطاع النشاط", "fr": "Secteur d'activité" }
    }

    const changeLang = (lng) => {
        setLang(lng);
    }

    const secteuActivitéList = [
        { "ar" : {"value": "أجهزة كهرومنزلية", 'text': "أجهزة كهرومنزلية"} , "fr" : {"value": "Électroménager", 'text': "Électroménager"} },
        { "ar" : {"value": "أثاث", 'text': "أثاث"} , "fr" : {"value": "Meubles", 'text': "Meubles"} },
        { "ar" : {"value": "أجهزة كهرومنزلية وأثاث", 'text': "أجهزة كهرومنزلية وأثاث"} , "fr" : {"value": "Électroménager et Meubles", 'text': "Électroménager et Meubles"} },
        { "ar" : {"value": "أخرى", 'text': "أخرى"} , "fr" : {"value": "Autre", 'text': "Autre"} },
   ];
    return (
        <div className="create-account Aide-page pt-5 pb-5 form-store-register" lang={Lang === 'ar' ? "ar" : 'fr'}>
            <div className="text-center mt-4 mb-5">
                <img alt="logodiardzair" src="/img/logo-fr.svg" width={186} height="59.911" />
            </div>
            <div className="ms-4 lang-section mb-4 text-center">
                <div className="sl-nav">
                    <ul>
                        <li>
                            <b className="lang-menu" style={{ color: 'black' }}>{Lang === "fr" ? 'Francais' : 'العربية'}</b>
                            {/* <b><img src={'/img/' + Lang + '.jpg'} className='lang-menu' alt='' /></b>  */}

                            <i className="fa fa-angle-down" style={{ color: 'black' }} aria-hidden="true" />
                            <div className="triangle" ></div>
                            <ul>
                                <div className='pad-lang'></div>
                                <li onClick={() => changeLang('fr')}><i className="sl-flag flag-fr" ><div id="French" /></i> <span className={Lang === "fr" ? "active lang-text" : 'lang-text'}> {Lang === "fr" ? 'Français' : 'الفرنسية'} </span></li>
                                <li onClick={() => changeLang('ar')}><i className="sl-flag flag-usa"><div id="English" /></i> <span className={Lang === "ar" ? "active lang-text" : 'lang-text'}> {Lang === "ar" ? 'العربية' : 'Arabe'}  </span></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='pb-hadi'></div>
            </div>

            {Loading &&
                <Loader />
            }
            {/* <h6 className="text-center mb-5 mt-4">
    Veuillez remplir le formulaire pour vous inscrire au service (Store)
    </h6> */}
            <div className='container py-3'>
                {error?.length > 0 && error?.map((item, i) => (

                    item.constructor == String ?
                        <div className="alert-store" role="alert">
                            - {item}
                        </div>
                        : ''

                ))
                }

            </div>
            <div className={`container  mt-2 d-flex `} lang={Lang === 'ar' ? "ar" : 'fr'} >
                <div className='form-store'>
                    <form className="row g-5"
                        dir={Lang === 'ar' ? 'rtl' : 'ltr'}
                    >

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.name[Lang]}</label>
                            <input maxLength={300} type="text" onChange={(e) => setNometPrenom(e.target.value)} value={NometPrenom} className="form-control" id="inputEmail4" />
                            <div className='err-cart-sum'>{Showerrors && errors.NometPrenom[Lang] !== '' ? errors.NometPrenom[Lang] : ''}</div>
                        </div>



                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress2" className="form-label">{Label?.Mobile[Lang]}</label>
                            <input type="tel" maxLength={12} onChange={(e) => setTel(e.target.value.replace(/[^0-9]+/g, ''))} value={Tel} className="form-control" id="inputAddress2" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.Tel[Lang] !== '' ? errors.Tel[Lang] : ''}</div>
                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.wilaya[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setWilaya(e.target.value)}>
                                <option disabled selected={Wilaya == "" ? "selected" : ''} value={''} >{Label?.wilaya[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.wilaya[Lang]}</label>
                                {WilayaList.length > 0 && WilayaList.map((item, i) => (

                                    <option value={item.value}>{item.text}</option>

                                ))
                                }

                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Wilaya[Lang] !== '' ? errors.Wilaya[Lang] : ''}</div>

                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.commune[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>
                                {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
                                <option disabled selected={Commune == "" ? "selected" : ''} value={''}>{Communesdenaissence.length > 0 ? Lang === "fr" ? "Commune" : "البلدية" : Lang === "fr" ? "Veuillez sélectionner La Wilaya" : "يرجى تحديد الولاية لإختيار البلدية"}</option>
                                {/* } */}


                                {Communesdenaissence.length > 0 && Communesdenaissence.map((item, i) => (
                                    <option selected={Commune === item?.commune} value={item?.commune}>{item?.commune}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Commune[Lang] !== '' ? errors.Commune[Lang] : ''}</div>

                        </div>

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.secteur[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setsecteuActivité(e.target.value)}>
                                <option disabled selected={secteuActivité == "" ? "selected" : ''} value={''} >{Label?.secteur[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.secteur[Lang]}</label>
                                {secteuActivitéList.length > 0 && secteuActivitéList.map((item, i) => (

                                    <option value={item[Lang].value}>{item[Lang].text}</option>

                                ))
                                }

                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.secteuActivité[Lang] !== '' ? errors.secteuActivité[Lang] : ''}</div>

                        </div>

                        {/* <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress" className="form-label">{Label?.secteur[Lang]}</label>
                            <input maxLength={300} type="text" className="form-control" onChange={(e) => setsecteuActivité(e.target.value)} value={secteuActivité} id="inputAddress" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.secteuActivité[Lang] !== '' ? errors.secteuActivité[Lang] : ''}</div>
                        </div> */}
                        <div className="col-12 mt-5">
                            <div onClick={() => GoNext()} className="store-form-register--btn">
                                {Lang === 'fr' ? "Inscription" : "إرسال الطلب"}
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    )
}



export default FormRegister