import axios from 'axios';
import { useEffect, useState } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import Accueil from '../components/Boutique/Accueil'
import Contact from '../components/Boutique/Contact';
import Header from '../components/Boutique/Header'
import Nav from '../components/Global/Nav';

const Boutique = () => {
  let { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPgae = searchParams.get('page') !== null ? searchParams.get('page') : 'accueil';

  // console.log(page);

  const navigate = useNavigate();


  const [Boutique, setBoutique] = useState([])

  useEffect(() => {

    if (slug == null || slug == 'null' || slug.length == 0) {

      return navigate('/')
      
    }

    const fetchData = async () => {
      // setLoading(true);
      try {
        const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/boutique/${slug}`);

        if (response['error'] == true) {

          navigate('/')

          // alert('hhhh')                  
        }
        // console.log(response[0]);
        setBoutique(response['data']);
        // console.log(DarTaksitCat)

      } catch (error) {
        navigate('/')

        console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();

  }, []);

  console.log(Boutique);

  return (
    <div className='store-page-section boutique-single-page-home'>
      <Nav />
      <Header Boutique={Boutique} page={currentPgae} />
      {currentPgae === "contact" ? <Contact Boutique={Boutique} /> : <Accueil Slug={slug} Boutique={Boutique} />}

    </div>
  )
}

export default Boutique