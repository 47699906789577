import { useState, useEffect, useContext } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import Menu from '../../components/DashboardUser/Menu';
import AuthContext from '../../Context/auth';

const VenteDetail = () => {

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)


    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Liste Des Ventes<span>Store</span></div>

                    <div className='dashboard-table'>
                        <table>
                            
                            <thead>
                                <tr style={{background : "#D5E6FF"}}>
                                    <th scope="col">Information</th>
                                    <th scope="col">Détail</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                  
                                    <td data-label="Information">id</td>
                                    <td data-label="Détail">12344</td>
                                   
                                </tr>
                                <tr>
                                    
                                    <td data-label="Information">Titre</td>
                                    <td data-label="Détail">MICRO ONDE BRANDT + GRILL 23L - 800W black GE2300B</td>
                                    </tr>
                                    
                                <tr>
                                   
                                    <td data-label="Information">Prix</td>
                                    <td data-label="Détail">84000 DA</td>
                                    
                                </tr>
                                <tr>
                                   
                                    <td data-label="Information">Catégorie </td>
                                    <td data-label="Détail">Electroménager</td>
                                   
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default VenteDetail