import {useEffect , useState , useContext} from 'react'
import { Outlet , useNavigate ,Navigate } from "react-router-dom";
import axios from 'axios';
import AuthContext from '../Context/auth';

const ProtectedRoute = ({Role}) => {

    console.log(Role);

    const { Auth, setAuth } = useContext(AuthContext);


    const navigate = useNavigate();
    const [ValidToken, setValidToken] = useState(null)

    const getToken = JSON.parse(localStorage.getItem('Token')) || '' ;

    

    console.log(getToken.token);

    const ValidateToken = async () => {
        console.log('run');
        try {
            const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/login/checktoken`,{},  {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken?.token}`
                },
            });

            console.log(response);
            // if (response['Valid'] == false) {
            //     return navigate('/')
            // }

            if (response['Valid'] == true && response['Role'] == Role) {
                // return navigate('/')
                setValidToken(true)
            }else{

                setValidToken(false)
                setAuth(false)

            }





        } catch (error) {
            // return navigate('/')
        }
    }

    useEffect(() => {

        if (!getToken) {
            // navigate('/')
            setValidToken(false)
            
        }else{
            
            ValidateToken();
        }


    })

    if (ValidToken === null) // waiting..
    return null;



  return (

    
    ValidToken ? <Outlet /> : <Navigate to={"/account"} replace={true} />
  )
}

export default ProtectedRoute