import { useState, useEffect } from 'react'


const CompteVendeur = ({ step, setstep, setForm }) => {


    const [Physiqueouentreprise, setPhysiqueouentreprise] = useState('Physique');
    const [NomBoutiques, setNomBoutiques] = useState('');
    const [NomAdminBoutique, setNomAdminBoutique] = useState('');
    const [PrenomAdminBoutique, setPrenomAdminBoutique] = useState('');
    const [TelAdminboutique, setTelAdminboutique] = useState('');
    const [Tel, setTel] = useState('')
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [RePassword, setRePassword] = useState('');



    console.log(Physiqueouentreprise);
    

    function GoNext() {
        // setShowerr(true)

        // console.log(FormValidate);

        // if (!FormValidate) {
        //   return
        // }

        setShowerrors(true)

        if (errors.Physiqueouentreprise !== '' || errors.NomAdminBoutique !== '' || errors.NomBoutiques !== '' || errors.PrenomAdminBoutique !== '' || errors.TelAdminboutique !== '' || errors.Tel !== '' || errors.Email !== '' || errors.Password !== '' || errors.rePassword !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }

        setForm((prev) => [
            {
                ...prev[0], ...{
                    "type_vendeur": Physiqueouentreprise,
                    "nom_boutique": NomBoutiques,
                    "tel": Tel,
                    "email_admin": Email,
                    "password": Password,
                    "nom_admin": NomAdminBoutique,
                    "prenom_admin": PrenomAdminBoutique,
                    "tel_admin": TelAdminboutique
                }
            }
        ])

        setstep(2)

        window.scrollTo({ top: 0, behavior: 'smooth' })

        // console.log('test', FormValidate);
    }

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
      }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Titreduproduit: '', Réferenceduproduit: '', Marque: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {




        if (Physiqueouentreprise?.toString().trim().length > 20 || Physiqueouentreprise?.toString().trim() == '' || hasNumber(Physiqueouentreprise) == true || Physiqueouentreprise?.toString().trim() !== 'Physique' && Physiqueouentreprise?.toString().trim() !== 'Entreprise') {
            seterrors((prev) => ({ ...prev, Physiqueouentreprise: 'Type vendeur incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Physiqueouentreprise: '' }))
        }

        if (NomBoutiques?.toString().trim().length > 300 || NomBoutiques?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, NomBoutiques: 'Nom boutique incorrect !' }))
        } else {
            seterrors((prev) => ({ ...prev, NomBoutiques: '' }))
        }

        if (Tel?.toString().trim().length > 12 || Tel?.toString().trim().length < 9 || Tel?.toString().trim() == '' || !onlyNumbers(Tel)) {
            seterrors((prev) => ({ ...prev, Tel:' Veuillez écrire un numéro de téléphone valide (Max 12) (Min 9)'}))
        } else {
            seterrors((prev) => ({ ...prev, Tel: '' }))
        }

        if (TelAdminboutique?.toString().trim().length > 12 || TelAdminboutique?.toString().trim().length < 9 || TelAdminboutique?.toString().trim() == '' || !onlyNumbers(TelAdminboutique)) {
            seterrors((prev) => ({ ...prev, TelAdminboutique: 'Veuillez écrire un numéro de téléphone valide (Max 12) (Min 9)' }))
        } else {
            seterrors((prev) => ({ ...prev, TelAdminboutique: '' }))
        }

        if (NomAdminBoutique?.toString().trim().length > 300 || NomAdminBoutique?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, NomAdminBoutique: 'Nom Admin incorrect !' }))
        } else {
            seterrors((prev) => ({ ...prev, NomAdminBoutique: '' }))
        }

        if (PrenomAdminBoutique?.toString().trim().length > 300 || PrenomAdminBoutique?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, PrenomAdminBoutique: 'Prenom Admin incorrect !' }))
        } else {
            seterrors((prev) => ({ ...prev, PrenomAdminBoutique: '' }))
        }

        if (Password?.toString().length > 300 || Password?.toString() == '' || Password?.toString().length < 6) {
            seterrors((prev) => ({ ...prev, Password: 'Le mot de passe est erroné (Min 6 lettres ou chiffres)' }))
        } else {
            seterrors((prev) => ({ ...prev, Password: '' }))
        }

        if (RePassword?.toString().length > 300 || RePassword?.toString() == '' || RePassword?.toString().length < 6 ||  RePassword !== Password) {
            seterrors((prev) => ({ ...prev, rePassword: 'Le mot de passe est erroné , Les deux mots de passe doivent être identiques (Min 6 lettres ou chiffres)' }))
        } else {
            seterrors((prev) => ({ ...prev, rePassword: '' }))
        }



        if (Email?.toString().trim().length > 300 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
            seterrors((prev) => ({ ...prev, Email: "Veuillez entrer un email valide !" }))
        } else {
            seterrors((prev) => ({ ...prev, Email: '' }))
        }



    }

    useEffect(() => {

        Validate();

    }, [Physiqueouentreprise, NomBoutiques, Tel, TelAdminboutique, Email, NomAdminBoutique, PrenomAdminBoutique, Password, RePassword])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])

    return (
        <div className={`container mt-2  ${step == 1 ? 'd-flex' : 'd-none'} `}>
            <div className='form-store'>
                <form className="row g-5">
                    <div className="col-md-12">
                        <div className="col-md-12 text-center mb-3">
                            <div className='mb-2'>vous êtes une personne physique ou une entreprise ? </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={Physiqueouentreprise == 'Physique'} onChange={(e) => setPhysiqueouentreprise(e.target.value)} defaultChecked={true} defaultValue="Physique" />
                                <label className="form-check-label" htmlFor="inlineRadio1">Physique</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={Physiqueouentreprise == 'Entreprise'} onChange={(e) => setPhysiqueouentreprise(e.target.value)} defaultValue="Entreprise" />
                                <label className="form-check-label" htmlFor="inlineRadio2">Entreprise</label>
                            </div>
                        </div>
                        <div className='err-cart-sum'>{Showerrors && errors.Physiqueouentreprise !== '' ? errors.Physiqueouentreprise : ''}</div>
                    </div>
                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputEmail4" className="form-label">Nom de la boutique</label>
                        <input maxLength={300} type="text" onChange={(e) => setNomBoutiques(e.target.value)} value={NomBoutiques} className="form-control" id="inputEmail4" />
                        <div className='err-cart-sum'>{Showerrors && errors.NomBoutiques !== '' ? errors.NomBoutiques : ''}</div>
                    </div>
                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputAddress" className="form-label">Nom de l'administrateur de la boutique ?</label>
                        <input maxLength={300} type="text" className="form-control" onChange={(e) => setNomAdminBoutique(e.target.value)} value={NomAdminBoutique} id="inputAddress" placeholder="" />
                        <div className='err-cart-sum'>{Showerrors && errors.NomAdminBoutique !== '' ? errors.NomAdminBoutique : ''}</div>
                    </div>
                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputAddress" className="form-label">Prenom de l'administrateur de la boutique ?</label>
                        <input maxLength={300} type="text" className="form-control" onChange={(e) => setPrenomAdminBoutique(e.target.value)} value={PrenomAdminBoutique} id="inputAddress" placeholder="" />
                        <div className='err-cart-sum'>{Showerrors && errors.PrenomAdminBoutique !== '' ? errors.PrenomAdminBoutique : ''}</div>
                    </div>


                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputAddress2" className="form-label">Numéro de téléphone de l'administrateur de la boutique ?</label>
                        <input type="tel" maxLength={12} onChange={(e) => setTelAdminboutique(e.target.value.replace(/[^0-9]+/g, ''))} value={TelAdminboutique} className="form-control" id="inputAddress2" placeholder="" />
                        <div className='err-cart-sum'>{Showerrors && errors.TelAdminboutique !== '' ? errors.TelAdminboutique : ''}</div>
                    </div>
                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputAddress2" className="form-label">Numéro téléphone de la boutique</label>
                        <input type="tel" maxLength={12} onChange={(e) => setTel(e.target.value.replace(/[^0-9]+/g, ''))} value={Tel} className="form-control" id="inputAddress2" placeholder="" />
                        <div className='err-cart-sum'>{Showerrors && errors.Tel !== '' ? errors.Tel : ''}</div>
                    </div>
                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputCity" className="form-label">Email de l'administrateur de la boutique ?</label>
                        <input maxLength={300} type="email" onChange={(e) => setEmail(e.target.value.replace(/[^a-zA-Z@.0-9]+/g, ''))} value={Email} className="form-control" id="inputCity" />
                        <div className='err-cart-sum'>{Showerrors && errors.Email !== '' ? errors.Email : ''}</div>
                    </div>
                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputState" className="form-label">Mot de Passe</label>
                        <input maxLength={300} type="password" onChange={(e) => setPassword(e.target.value)} value={Password} className="form-control" id="inputCity" />
                        <div className='err-cart-sum'>{Showerrors && errors.Password !== '' ? errors.Password : ''}</div>
                    </div>
                    <div className="col-lg-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputState" className="form-label">Répéter le mot de passe</label>
                        <input maxLength={300} type="password" onChange={(e) => setRePassword(e.target.value)} value={RePassword} className="form-control" id="inputCity" />
                        <div className='err-cart-sum'>{Showerrors && errors.rePassword !== '' ? errors.rePassword : ''}</div>

                    </div>

                    <div className="col-12 mt-5">
                        <div onClick={() => GoNext()} className="store-next-btn">Suivant</div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default CompteVendeur