import { useEffect, useState } from 'react'
import axios from 'axios';

const Search = () => {

    const [SearchKey, setSearchKey] = useState('')

    const [Category, setCategory] = useState([])
    const [Cat, setCat] = useState('')

    const [Wilaya, setWilaya] = useState([])
    const [WilayaS, setWilayaS] = useState('')

    useEffect(() => {

        setWilaya(
            [   "ADRAR",
                "CHLEF",
                "LAGHOUAT",
                "OUM EL BOUAGHI",
                "BATNA",
                "BEJAIA",
                "BISKRA",
                "BECHAR",
                "BLIDA",
                "BOUIRA",
                "TAMANRASSET",
                "TEBESSA",
                "TLEMCEN",
                "TIARET",
                "TIZI OUZOU",
                "ALGER",
                "DJELFA",
                "JIJEL",
                "SETIF",
                "SAIDA",
                "SKIKDA",
                "SIDI BELABBES",
                "ANNABA",
                "GUELMA",
                "CONSTANTINE",
                "MEDEA",
                "MOSTAGANEM",
                "M SILA",
                "MASCARA",
                "OUARGLA",
                "ORAN",
                "EL BAYADH",
                "ILLIZI",
                "BORDJ BOU ARRERIDJ",
                "BOUMERDES",
                "EL TARF",
                "TINDOUF",
                "TISSEMSILT",
                "EL OUED",
                "KHENCHELA",
                "SOUK AHRAS",
                "TIPAZA",
                "MILA",
                "AIN DEFLA",
                "NAAMA",
                "AIN TEMOUCHENT",
                "GHARDAIA",
                "RELIZANE",
                "Timimoun",
                "Bordj Badji Mokhtar",
                "Ouled Djellal",
                "Béni Abbès",
                "In Salah",
                "In Guezzam",
                "Touggourt",
                "Djanet",
                "El M'Ghair",
                "El Meniaa"]
            )

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/categoriedz`);
                // console.log(response[0]);
                setCategory(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    

    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/wilaya`);
                // console.log(response[0]);
                // setWilaya(response['wilayas']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            window.location =`/search?page=1${SearchKey.trim() ? `&key=${SearchKey}` :''}${Cat.trim() ? `&categorie=${Cat}` :''}${WilayaS.trim() ? `&wilaya=${WilayaS}` :''}`;
        }
      };


    return (
        <div className="container">
            <div className='search-bar-section'>
                <div className='search-sections'>
                    <div className="search-sec d-none d-lg-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36.188" height="36.178" viewBox="0 0 36.188 36.178">
                            <path id="icons8-search" d="M28.226,14.4a13.845,13.845,0,1,0,9.782,4.044A13.791,13.791,0,0,0,28.226,14.4Zm-.018,2.15a11.5,11.5,0,0,1,8.234,3.424,11.613,11.613,0,1,1-8.234-3.424Zm0,2.186a9.259,9.259,0,0,0-6.668,2.805,9.463,9.463,0,0,0-2.769,6.34,1.073,1.073,0,0,0,1.057,1.129h.036a1.114,1.114,0,0,0,1.093-1.057,7.391,7.391,0,0,1,2.113-4.882,7.226,7.226,0,0,1,5.138-2.149,1.093,1.093,0,0,0,0-2.186Zm-6.3,13.735A1.093,1.093,0,1,0,23,33.564,1.093,1.093,0,0,0,21.905,32.471Zm17.621,6.012a1.012,1.012,0,0,0-.751.328,1.056,1.056,0,0,0,0,1.53l.911.911a3.133,3.133,0,0,0-.328,1.421A3.278,3.278,0,0,0,40.3,45L44.968,49.6a3.286,3.286,0,0,0,2.331.983,3.407,3.407,0,0,0,2.332-.947,3.276,3.276,0,0,0,0-4.627l-4.663-4.664a3.278,3.278,0,0,0-2.332-.947,3.133,3.133,0,0,0-1.421.328l-.911-.911A1.1,1.1,0,0,0,39.526,38.483Zm3.11,3.061a.992.992,0,0,1,.765.328L48.028,46.5a1.082,1.082,0,1,1-1.53,1.53l-4.627-4.59a1.13,1.13,0,0,1-.328-.8.992.992,0,0,1,.328-.765A1.048,1.048,0,0,1,42.636,41.544Z" transform="translate(-14.4 -14.4)" fill="#e01c3b" />
                        </svg>
                        <input onKeyDown={handleKeyDown} type={'text'} onChange={(e)=> setSearchKey(e.target.value)} value={SearchKey} placeholder="Rechercher ..." />
                    </div>
                    <div className="search-cat-sec d-none d-lg-block">
                        <div className='search-small-title'>Catégorie</div>
                        <select onChange={(e)=> setCat(e.target.value)}>
                            <option value="">Toutes les Catégories</option>
                            {Category?.length > 0 && Category?.map((item, i) => (

                                <option value={item.id_cat}>{item.cat_name}</option>

                            ))
                            }
                        </select>
                    </div>
                    <div className="search-cat-sec d-lg-block">
                        <div className='search-small-title'>Wilaya</div>
                        <select onChange={(e)=> setWilayaS(e.target.value)}>
                            <option value="">Toutes Les Wilayas</option>
                            {Wilaya?.length > 0 && Wilaya?.map((item, i) => (

                                <option value={item}>{`(${(i+1)}) ${item}` }</option>

                            ))
                            }
                        </select>
                    </div>
                    <a className='search-bar-store-btn' href={`/search?page=1${SearchKey.trim() ? `&key=${SearchKey}` :''}${Cat.trim() ? `&categorie=${Cat}` :''}${WilayaS.trim() ? `&wilaya=${WilayaS}` :''}`}>
                    <div className='search-btn-sec'>
                        Rechercher
                        
                    </div></a>

                </div>
            </div>

        </div>
    )
}

export default Search