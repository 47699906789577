import React from 'react'
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Header = () => {

  // function Msg() {
  //   Swal.fire(
      
      
  //     {
  //       title: 'Bientôt!',
  //       type: 'error',
  //       text:  'La boutique sera bientôt lancée',
  //       // timer: 2000,
  //       // showConfirmButton: false,
  //       confirmButtonText: "Plus d'information",
  //       confirmButtonColor: '#e01c3b',
  //       icon: 'success',
  //       // didClose: () => window.scrollTo(0, 0)
  //     }
  //   ).then((result) => {
  //     if (result.value) {
  //       window.location.href = `/assistence`
  //     }
  //   }); 
  // }
  return (
    <div className="">
        <header className="masthead"  style={{ background: `linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%) , url('/img/aide.png')` }}>
        <div className="container h-100">
            <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
                <h1 className="aide-header-title"> Commencez a vendre en ligne</h1>
                <p className="aide-header-sub mt-5"> Lancez votre boutique en ligne et générez des ventes facilement<br></br> sans aucune connaissance technique</p>
            <div className='aide-header-btn mt-5' style={{cursor:'pointer'}}>
              <Link to={'/createaccount'}>
                Commencez
              </Link>
              </div>
            </div>
            </div>
        </div>
        </header> 

    </div>
  )
}

export default Header