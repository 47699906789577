import { useEffect, useState } from 'react'
import axios from 'axios';
import Spinner from '../Global/Spinner';

const Boutiques = () => {

    const [StoreBoutiques, setStoreBoutiques] = useState([])
    const [Loading, setLoading] = useState(true)


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/boutique/topads`);
                // console.log(response[0]);
                setStoreBoutiques(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    return (
        <div className="store-boutiques mb-5 pb-5">
            <div className="container">
                <div className="store-cat-head">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="26.572" height="25" viewBox="0 0 26.572 25"><path id="Mask" d="M21.248,25A2.084,2.084,0,1,1,23.3,22.916,2.072,2.072,0,0,1,21.248,25ZM7.54,25A2.084,2.084,0,1,1,9.6,22.916,2.073,2.073,0,0,1,7.54,25Zm13.024-6.945H8.226A1.38,1.38,0,0,1,6.9,17.032L3.065,2.777H1.371A1.381,1.381,0,0,1,0,1.389,1.381,1.381,0,0,1,1.371,0H4.112A1.381,1.381,0,0,1,5.436,1.024l.845,3.143H23.828A2.714,2.714,0,0,1,26.16,5.484a2.793,2.793,0,0,1,.121,2.7l-4.491,9.1A1.363,1.363,0,0,1,20.563,18.055Z" fill="#363c5a" /></svg>
                        Nos Boutiques</p>
                    <a href='search?page=1'><div className="store-cat-btn">Toutes les boutiques</div></a>
                </div>
                <div className="row mt-5">
                    {Loading ? <Spinner /> : ''}
                    {!Loading && StoreBoutiques?.length > 0 && StoreBoutiques?.map((item, i) => (



                        <div className="col-md-6 col-lg-4 col-12">
                            <a href={'/boutique/' + item?.boutique_slug}>
                                <div className="boutique-card">
                                    <img className="img-fluid img-b-bg" src={item?.bg !== null && item?.bg !== '' && item?.bg !== 'undefined' ? `${process.env.REACT_APP_LOCAL_URL}/images/boutiques/bg/${item?.bg}` : 'https://www.orbis.com.ar/wp-content/themes/barberry/images/placeholder.jpg'} />
                                    <div className="boutique-content">
                                        <div className="boutique-desc">
                                            <p className="boutique-title">{item?.nom}</p>
                                            <p className="boutique-map">{item?.commune} , {item?.wilaya}</p>
                                        </div>
                                        <div className="boutique-logo">
                                            <img className="img-fluid" src={item?.logo !== null && item?.logo !== '' && item?.logo !== 'undefined' ? `${process.env.REACT_APP_LOCAL_URL}/images/boutiques/logo/${item?.logo}` : 'https://www.orbis.com.ar/wp-content/themes/barberry/images/placeholder.jpg'} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                    ))
                    }









                </div>
            </div>
        </div>
    )
}

export default Boutiques