import React from 'react'
import Avantage from '../components/Aide/Avantage'
import Etap from '../components/Aide/Etap'
import Header from '../components/Aide/Header'
import Qr from '../components/Aide/Qr'
// import Footer from '../components/ooter'
import Nav from '../components/Global/Nav';

const Aide = () => {
  return (
<>
    <Nav />

    <div className='Aide-page'> 
        <Header />
        <Etap />
        <Avantage />
        <Qr />
        {/* <Footer /> */}
    </div>
    </>
  )
}

export default Aide