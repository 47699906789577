import { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom';


const Header = ({ page, Boutique }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const currentPgae = searchParams.get('page') !== null ? searchParams.get('page') : 'accueil';

    const bgLink = Boutique?.bg !== null && Boutique?.bg !== '' && Boutique?.bg !== 'undefined' ? `${process.env.REACT_APP_LOCAL_URL}/images/boutiques/bg/${Boutique?.bg}` : 'https://c0.wallpaperflare.com/preview/115/811/694/simple-background-abstract-slick.jpg';
    return (
        <div className='boutique-hero'>
            <div className="store-hero" style={{ backgroundImage: `linear-gradient(179deg, #343434b8, #00000000),url(${bgLink})` }}>
                <div className='container'>
                    <div className='store-boutique-title-logo'>
                        <div className='boutique-single-logo'>
                            <img className='img-fluid' src={Boutique?.logo !== null && Boutique?.logo !== '' && Boutique?.logo !== 'undefined' ? `${process.env.REACT_APP_LOCAL_URL}/images/boutiques/logo/${Boutique?.logo}` : 'https://www.orbis.com.ar/wp-content/themes/barberry/images/placeholder.jpg'} />
                        </div>
                        <div className="boutique-single-info">
                            <div className='boutique-single-title'>{Boutique?.nom?.length > 40 ? Boutique?.nom?.substring(0, 30) + '....' : Boutique?.nom}</div>
                            <div className='boutique-single-adresse'>{Boutique?.adresse?.length > 40 ? Boutique?.adresse?.substring(0, 30) + '....' : Boutique?.adresse}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='boutique-navbar'>
                <div className='boutique-single-top-area'>




                    <div className='boutique-single-nav'>
                        <div className='container'>
                            <div className='b-nav-section'>
                                <div onClick={() => setSearchParams({ page: "accueil" })} className={page !== "contact" ? "b-single-nav-active" : "b-single-nav"}><Link to={'/boutique/accueil'}>Accueil</Link></div>
                                <div onClick={() => setSearchParams({ page: "contact" })} className={page === "contact" ? "b-single-nav-active" : "b-single-nav"}><Link to={'/boutique/contact'}>Contact</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header