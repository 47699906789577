import { useEffect, useState } from 'react'
import axios from 'axios';
import LoadData from '../Global/LoadData';
import Spinner from '../Global/Spinner';

const Category = () => {


  const [StoreCat, setStoreCat] = useState([])
  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/categoriedz/`);
        // console.log(response[0]);
        setStoreCat(response['data']);
        // console.log(DarTaksitCat)

      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }

    fetchData();

  }, []);
  return (
    <div className="store-cat">
      <div className="container">
        <div className="store-cat-head">
          <p><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Mask" d="M21.334,24H16a2.669,2.669,0,0,1-2.666-2.666V16A2.669,2.669,0,0,1,16,13.333h5.334A2.669,2.669,0,0,1,24,16v5.334A2.669,2.669,0,0,1,21.334,24ZM8,24H2.666A2.669,2.669,0,0,1,0,21.334V16a2.669,2.669,0,0,1,2.666-2.666H8A2.669,2.669,0,0,1,10.667,16v5.334A2.669,2.669,0,0,1,8,24ZM21.334,10.667H16A2.669,2.669,0,0,1,13.333,8V2.666A2.669,2.669,0,0,1,16,0h5.334A2.669,2.669,0,0,1,24,2.666V8A2.669,2.669,0,0,1,21.334,10.667ZM8,10.667H2.666A2.669,2.669,0,0,1,0,8V2.666A2.669,2.669,0,0,1,2.666,0H8a2.669,2.669,0,0,1,2.666,2.666V8A2.669,2.669,0,0,1,8,10.667Z" fill="#363c5a" /></svg>
            Nos Catégories</p>
          <a href='search?page=1'><div className="store-cat-btn">Toutes les Catégories</div></a>
        </div>

        {/* <div className="row mt-4 gy-3">
            <div className="col-md-6"> <a href={`search?page=1&categorie=7`}><img className="img-fluid" src="./img/electro.png" /></a> </div>
            <div className="col-md-6"> <a href={`search?page=1&categorie=7`}><img className="img-fluid" src="./img/meubles.png" /></a> </div>
        </div> */}

        <div className="row mt-4 gy-3">

          {Loading ? <Spinner /> : ''}
          {!Loading && StoreCat?.length > 0 && StoreCat?.map((item, i) => (


            <div className="col-md-6">
              <a href={`search?page=1&categorie=${item?.id_cat}`}>
                <img className="img-fluid" src={`${process.env.REACT_APP_LOCAL_URL}/images/categories/${item?.cat_img}`} />
              </a>
            </div>

          ))
          }


        </div>
      </div>
    </div>
  )
}

export default Category