import { useState } from 'react'

const Header = ({ error }) => {
  console.log(error);
  return (
    <div>
      <div className="">
        <header className="masthead" style={{ background: `linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%) , url('/img/aide.png')` }}>
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12 text-center">
                <h1 className="aide-header-title">inscrivez-vous et commencez à vendre dés maintenet</h1>
                <p className="aide-header-sub mt-5">Créez votre propre compte vendeur</p>
              </div>
            </div>
          </div>
        </header>

      </div>
      <div className='container py-3'>
        {error?.length > 0 && error?.map((item, i) => (

          item.constructor == String ?
            <div className="alert-store" role="alert">
              - {item}
            </div>
            : ''

        ))
        }

      </div>
    </div>
  )
}

export default Header