import React from 'react'

const Header = () => {
  return (
    <div> 
        <div className="search-hero" style={{ background: `url('/img/bg.png')` }}>
        <p className="store-h1 d-none">Rechercher <span>Store</span></p>
        </div>
    </div>
  )
}

export default Header