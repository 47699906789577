import {useEffect,useState} from 'react'
import CompteVendeur from '../components/CreateAccount/CompteVendeur'
import Header from '../components/CreateAccount/Header'
import Info from '../components/CreateAccount/Info'

const CreateAccount = () => {
  const [step, setstep] = useState(1)
  const [Form, setForm] = useState([{}])
  const [error, seterror] = useState([]);

  console.log(Form);

  console.log(error);
  return (
    <div className="create-account Aide-page"> 
        <Header error={error}/> 
        <CompteVendeur Form={Form} setForm={setForm}  step={step} setstep={setstep} />
        <Info Form={Form} setForm={setForm} step={step} setstep={setstep} seterror={seterror}/>
    </div>
  )
}

export default CreateAccount