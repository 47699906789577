import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



const Create = () => {

    

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    





    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);




    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [CatName, setCatName] = useState('')
    
    const [Validé, setValidé] = useState(false)
    const [Image1, setImage1] = useState('')
    // const [Image2, setImage2] = useState('')



    const [preview1, setPreview1] = useState()
    // const [preview2, setPreview2] = useState()
    const [initpreview1, setinitPreview1] = useState(false)
    // const [initpreview2, setinitPreview2] = useState(false)


    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
      }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ CatName: '', Email: '', Adresse: '', Site: '', Facebook: '', Instagram: '', Heurededébut: '', Heuredefin: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        if (CatName?.toString().trim().length > 300 || CatName?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, CatName: 'Veuillez écrire le nom de la Catégorie !! (Max 300 Caractères)' }))
        } else {
            seterrors((prev) => ({ ...prev, CatName: '' }))
        }

      

        if (Image1) {
            if (Image1.size > 2097152 ) {
                seterrors((prev) => ({ ...prev, Image1: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image1: '' }))
            }
            
        }else{
            seterrors((prev) => ({ ...prev, Image1: '' }))
        }

        // if (Image2) {
        //     if (Image2.size > 4097152 ) {
        //         seterrors((prev) => ({ ...prev, Image2: 'La couverture que vous avez sélectionné ne doit pas dépasser 3 Mo' }))
        //     } else {
        //         seterrors((prev) => ({ ...prev, Image2: '' }))
        //     }
            
        // }else{
        //     seterrors((prev) => ({ ...prev, Image2: '' }))
        // }
    


    }

    useEffect(() => {

        Validate();

    }, [CatName,Image1])


    useEffect(() => {
        if (!Image1) {
            setPreview1(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image1)
        setPreview1(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image1])


    // useEffect(() => {
    //     if (!Image2) {
    //         setPreview2(undefined)
    //         return
    //     }

    //     const objectUrl = URL.createObjectURL(Image2)
    //     setPreview2(objectUrl)

    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [Image2])


    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])



    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        if (errors.CatName !== '' ||  errors.Image1 !== '' 
        ) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 60);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }



        const formData = new FormData();
        formData.append("nom_categorie", CatName);
      
        formData.append("active", Validé == true ? 1 : 0);
     
        formData.append("imgcat", Image1 || null);
        // formData.append("bg", Image2 || null);

    
        setLoading(true)


        axios.post(`${process.env.REACT_APP_LOCAL_URL}/categoriedz/new_categorie`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)
                    if (response.data['errorList'].constructor == Array) {
                        
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Votre Catégorie a été créée avec succès',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function() {
                        window.location = "/category";
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }




    return (
        <div className='dashboard'>

            {Loading && 
            <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Créer Une Catégorie <span>Store</span></div>
                    <div className='page-content'>
                        {/* {err?.length > 0 && err?.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        <form className="row g-5">
                            <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (
                                    
                                    item.constructor == String ?
                                    <div className="alert-store" role="alert">
                                        - {item}
                                    </div>
                                    :''

                                ))
                                }

                            </div>
                            <div className="col-md-12">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom de Catégorie</label>
                                <input type="text" onChange={(e) => setCatName(e.target.value)} value={CatName} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div>
                            </div>
                            
                                                                                                                                                   

                            <div className="col-md-12 d-flex justify-content-around flex-wrap">
                                <div className="mb-3">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label for="inputState" class="form-label">Image</label>

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up mt-3'>
                                        {Image1 && <div className='up-close' onClick={() => setImage1(null)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput1.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image1 &&
                                            <div onClick={() => { hiddenFileInput1.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview1} />

                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image1 !== '' ? errors.Image1 : ''}</div>
                                    <input ref={hiddenFileInput1}  accept="image/png, image/jpg ,image/jpeg ,image/webp" className="form-control d-none" type="file" onChange={(e) => e.target.files.length > 0 ?  setImage1(e.target.files[0]) : setImage1()} id="formFile" />
                                </div>

                                <div className="col-md-12 mt-4 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Validé</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setValidé(e.target.checked)} checked={Validé} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>
                               




                            </div>

                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Ajouter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Create