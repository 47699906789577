import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



const UpdateVendeur = () => {

    const CommuneDeNaissanceMount = useRef(true);
    const DairaMount = useRef(true);
    const CommuneMount = useRef(true);
    const PostalCodeMount = useRef(true);




    const { id } = useParams();
    const [ProductData, setProductData] = useState('')





    const [Category, setCategory] = useState([])
    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/categoriedz`);
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setCategory(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    console.log(Category);

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)





    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const hiddenFileInput3 = useRef(null);
    const hiddenFileInput4 = useRef(null);
    const hiddenFileInput5 = useRef(null);





    // useEffect(() => {


    //     if (Auth) {
    //         console.log('hi!');

    //     } else {
    //         console.log('no!');
    //         return navigate("/");
    //     }

    //     console.log(Auth);


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)


    const [Physiqueouentreprise, setPhysiqueouentreprise] = useState('');
    const [NomBoutiques, setNomBoutiques] = useState('');
    const [NomAdminBoutique, setNomAdminBoutique] = useState('');
    const [PrenomAdminBoutique, setPrenomAdminBoutique] = useState('');
    const [TelAdminboutique, setTelAdminboutique] = useState('');
    const [Tel, setTel] = useState('')
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [RePassword, setRePassword] = useState('');
    const [EmailParrain, setEmailParrain] = useState('');
    const [nomdupropriétairedelentreprise, setnomdupropriétairedelentreprise] = useState('');
    const [Prenomdupropriétairedelentreprise, setPrenomdupropriétairedelentreprise] = useState('');

    const [Date, setDate] = useState('');
    const [Addrese, setAddrese] = useState('')
    const [Typedepiéce, setTypedepiéce] = useState('')
    const [Nif, setNif] = useState('')
    const [Nrc, setNrc] = useState('')
    const [Tva, setTva] = useState('oui')
    const [BoutiquesPlusieurs, setBoutiquesPlusieurs] = useState('oui')
    const [secteurdactivité, setsecteurdactivité] = useState('')

    const [Wilaya, setWilaya] = useState('')
    const [Wilayas, setWilayas] = useState([])

    const [Daira, setDaira] = useState('')
    const [Dairas, setDairas] = useState([])

    const [Commune, setCommune] = useState('')
    const [Communes, setCommunes] = useState([])

    const [Wilayadenaissence, setWilayadenaissence] = useState('')
    const [Wilayasdenaissence, setWilayasdenaissence] = useState([])

    const [Communedenaissence, setCommunedenaissence] = useState('')
    const [Communesdenaissence, setCommunesdenaissence] = useState([])

    const [CodePostal, setCodePostal] = useState('')
    const [CodePostals, setCodePostals] = useState([])

    // const [Nomdevosboutique, setNomdevosboutique] = useState('')

    const [attestationdimmatriculationduNIF, setattestationdimmatriculationduNIF] = useState('')
    const [OldattestationdimmatriculationduNIF, setOldattestationdimmatriculationduNIF] = useState('')

    const [copiedelapiécedidentité, setcopiedelapiécedidentité] = useState('')
    const [Oldcopiedelapiécedidentité, setOldcopiedelapiécedidentité] = useState('')

    const [extraitduregistreducommerce, setextraitduregistreducommerce] = useState('')
    const [Oldextraitduregistreducommerce, setOldextraitduregistreducommerce] = useState('')




    const [immatriculationauregistreducommerce, setimmatriculationauregistreducommerce] = useState('')



    const [Articledimposition, setArticledimposition] = useState('')

    const [AllData, setAllData] = useState(new FormData())


    console.log(Physiqueouentreprise);

    function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => {


            if (key === "piece_idnt" || key === "rc_file" || key === "nif_file") {

                if (key === "piece_idnt" && copiedelapiécedidentité) {

                    formData.append(key, object[key]['file'], object[key]['filename'])
                }

                if (key === "rc_file" && extraitduregistreducommerce) {

                    formData.append(key, object[key]['file'], object[key]['filename'])
                }
                if (key === "nif_file" && attestationdimmatriculationduNIF) {

                    formData.append(key, object[key]['file'], object[key]['filename'])
                }
            } else {

                formData.append(key, object[key])
            }



        }

        );

        console.log(formData.values());
        return formData;
    }


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response['data']);
                setProductData(response['data'])


                console.log(response?.data[0]?.type_vendeur);


                setPhysiqueouentreprise(response?.data[0]?.type_vendeur || '');
                setNomBoutiques(response?.data[0]?.nom_boutique || '');
                setnomdupropriétairedelentreprise(response?.data[0]?.nom_prop || '');
                setNomAdminBoutique(response?.data[0]?.nom_admin || '');
                setPrenomAdminBoutique(response?.data[0]?.prenom_admin || '');
                setTelAdminboutique(response?.data[0]?.tel_admin || '');
                setTel(response?.data[0]?.tel || '');
                setEmail(response?.data[0]?.email_admin || '');
                setEmailParrain(response?.data[0]?.email_parrain || '');
                setPrenomdupropriétairedelentreprise(response?.data[0]?.prenom_prop || '');
                setDate(response?.data[0]?.date_naissance || '');
                setsecteurdactivité(response?.data[0]?.secteur_activite || '');
                setAddrese(response?.data[0]?.adresse || '');
                setWilayadenaissence(response?.data[0]?.wilaya_naissance || '');
                setCommunedenaissence(response?.data[0]?.commune_naissance || '');
                setWilaya(response?.data[0]?.wilaya || '');
                setDaira(response?.data[0]?.dairas || '');
                setCommune(response?.data[0]?.commune || '');
                setCodePostal(response?.data[0]?.code_postal || '');
                setTypedepiéce(response?.data[0]?.type_piece_idnt || '');
                setimmatriculationauregistreducommerce(response?.data[0]?.nin || '');
                setNif(response?.data[0]?.nif || '');
                setNrc(response?.data[0]?.nrc || '');
                setArticledimposition(response?.data[0]?.article_imposition || '');
                setTva(response?.data[0]?.tva || '');
                setBoutiquesPlusieurs(response?.data[0]?.tva || '');

                setOldcopiedelapiécedidentité(response?.data[0]?.piece_idnt || '');
                setOldattestationdimmatriculationduNIF(response?.data[0]?.nif_file || '');
                setOldextraitduregistreducommerce(response?.data[0]?.rc_file || '');
























                // setTitreduproduit(response['data']['titre_produit'])
                // setRéferenceduproduit(response['data']['ref_produit'])
                // setMarque(response['data']['marque'])
                // // setCatégorie(response['data']['marque'])
                // setQuantité(String(response['data']['qte']))
                // setQuantitéunit(String(response['data']['qte_unit']))
                // // setDatedébutdevente(response['data'][''])
                // setPrix(String(response['data']['prix']))
                // setConditionderetour(response['data']['condition_retour']) 
                // setCaractéristiques(response['data']['caracteristiques'])
                // setDescription(response['data']['description'])
                // setShortdescription(response['data']['ShortDescription'])

                // setPreview1(`${process.env.REACT_APP_LOCAL_URL}/images/${response['data']['image1']}`)
                // setImage1(true)




            } catch (error) {
            setLoading(false);

                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [errors, seterrors] = useState({})
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        if (Physiqueouentreprise?.toString().trim().length > 20 || Physiqueouentreprise?.toString().trim() == '' || hasNumber(Physiqueouentreprise) == true || Physiqueouentreprise?.toString().trim() !== 'Physique' && Physiqueouentreprise?.toString().trim() !== 'Entreprise') {
            seterrors((prev) => ({ ...prev, Physiqueouentreprise: 'Type vendeur incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Physiqueouentreprise: '' }))
        }

        if (NomBoutiques?.toString().trim().length > 300 || NomBoutiques?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, NomBoutiques: 'Nom boutique incorrect !' }))
        } else {
            seterrors((prev) => ({ ...prev, NomBoutiques: '' }))
        }

        if (Tel?.toString().trim().length > 12 || Tel?.toString().trim().length < 9 || Tel?.toString().trim() == '' || !onlyNumbers(Tel)) {
            seterrors((prev) => ({ ...prev, Tel:' Veuillez écrire un numéro de téléphone valide (Max 12) (Min 9)'}))
        } else {
            seterrors((prev) => ({ ...prev, Tel: '' }))
        }

        if (TelAdminboutique?.toString().trim().length > 12 || TelAdminboutique?.toString().trim().length < 9 || TelAdminboutique?.toString().trim() == '' || !onlyNumbers(TelAdminboutique)) {
            seterrors((prev) => ({ ...prev, TelAdminboutique: 'Veuillez écrire un numéro de téléphone valide (Max 12) (Min 9)' }))
        } else {
            seterrors((prev) => ({ ...prev, TelAdminboutique: '' }))
        }

        if (NomAdminBoutique?.toString().trim().length > 300 || NomAdminBoutique?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, NomAdminBoutique: 'Nom Admin incorrect !' }))
        } else {
            seterrors((prev) => ({ ...prev, NomAdminBoutique: '' }))
        }

        if (PrenomAdminBoutique?.toString().trim().length > 300 || PrenomAdminBoutique?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, PrenomAdminBoutique: 'Prenom Admin incorrect !' }))
        } else {
            seterrors((prev) => ({ ...prev, PrenomAdminBoutique: '' }))
        }

        // if (Password?.toString().length > 300 || Password?.toString() == '' || Password?.toString().length < 6) {
        //     seterrors((prev) => ({ ...prev, Password: 'Le mot de passe est erroné (Min 6 lettres ou chiffres)' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Password: '' }))
        // }

        // if (RePassword?.toString().length > 300 || RePassword?.toString() == '' || RePassword?.toString().length < 6 ||  RePassword !== Password) {
        //     seterrors((prev) => ({ ...prev, rePassword: 'Le mot de passe est erroné , Les deux mots de passe doivent être identiques (Min 6 lettres ou chiffres)' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, rePassword: '' }))
        // }



        if (Email?.toString().trim().length > 300 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
            seterrors((prev) => ({ ...prev, Email: "Veuillez entrer un email valide !" }))
        } else {
            seterrors((prev) => ({ ...prev, Email: '' }))
        }






        if (nomdupropriétairedelentreprise?.toString().trim().length > 300 || nomdupropriétairedelentreprise?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, nomdupropriétairedelentreprise: 'Nom du propriétaire de l\'entreprise incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, nomdupropriétairedelentreprise: '' }))
        }

        if (Prenomdupropriétairedelentreprise?.toString().trim().length > 300 || Prenomdupropriétairedelentreprise?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Prenomdupropriétairedelentreprise: 'Prénom du propriétaire de l\'entreprise incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Prenomdupropriétairedelentreprise: '' }))
        }
        if (EmailParrain?.toString().trim().length > 300 || EmailParrain?.toString().trim() == '' || !isValidEmail(EmailParrain?.toString().trim())) {
            seterrors((prev) => ({ ...prev, EmailParrain: 'Veuillez entrer un email valide' }))
        } else {
            seterrors((prev) => ({ ...prev, EmailParrain: '' }))
        }
        if (Date?.toString().trim().length > 300 || Date?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Date: 'Date de naissance incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Date: '' }))
        }
        if (Addrese?.toString().trim().length > 300 || Addrese?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Addrese: 'Addrese incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Addrese: '' }))
        }
        if (secteurdactivité?.toString().trim().length > 300 || secteurdactivité?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, secteurdactivité: "Secteur d'activité incorrect" }))
        } else {
            seterrors((prev) => ({ ...prev, secteurdactivité: '' }))
        }
        if (Typedepiéce?.toString().trim().length > 300 || Typedepiéce?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Typedepiéce: 'Type de piéce incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Typedepiéce: '' }))
        }
        if (Nif?.toString().trim() == '' || onlyNumbers(Nif) == false || Nif?.toString().trim().length > 30) {
            seterrors((prev) => ({ ...prev, Nif: 'Nif incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Nif: '' }))
        }
        if (Nrc?.toString().trim() == '' || Nrc?.toString().trim().length > 30) {
            seterrors((prev) => ({ ...prev, Nrc: 'Nrc incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Nrc: '' }))
        }
        if (Tva?.toString().trim() == '' || (Tva?.toString().trim() !== 'oui' && Tva?.toString().trim() !== 'non')) {
            seterrors((prev) => ({ ...prev, Tva: 'Tva incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Tva: '' }))
        }
        if (BoutiquesPlusieurs?.toString().trim() == '' || (BoutiquesPlusieurs?.toString().trim() !== 'oui' && BoutiquesPlusieurs?.toString().trim() !== 'non')) {
            seterrors((prev) => ({ ...prev, BoutiquesPlusieurs: 'Champ incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, BoutiquesPlusieurs: '' }))
        }
        if (immatriculationauregistreducommerce?.toString().trim() == '' || immatriculationauregistreducommerce?.toString().trim().length > 30) {
            seterrors((prev) => ({ ...prev, immatriculationauregistreducommerce: 'immatriculation au registre du commerce incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, immatriculationauregistreducommerce: '' }))
        }

        if (Wilaya?.toString().trim() == '' || Wilaya?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Wilaya: 'Wilaya incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Wilaya: '' }))
        }
        if (Daira?.toString().trim() == '' || Daira?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Daira: 'Daira incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Daira: '' }))
        }

        if (Commune?.toString().trim() == '' || Commune?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Commune: 'Commune incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Commune: '' }))
        }

        if (CodePostal?.toString().trim() == '' || CodePostal?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, CodePostal: 'CodePostal incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, CodePostal: '' }))
        }

        if (Wilayadenaissence?.toString().trim() == '' || Wilayadenaissence?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Wilayadenaissence: 'Wilaya de naissence incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Wilayadenaissence: '' }))
        }

        if (Communedenaissence?.toString().trim() == '' || Communedenaissence?.toString().trim().length > 300) {
            seterrors((prev) => ({ ...prev, Communedenaissence: 'Commune de naissence incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Communedenaissence: '' }))
        }
        if (Articledimposition?.toString().trim() == '' || Articledimposition?.toString().trim().length > 30 ) {
            seterrors((prev) => ({ ...prev, Articledimposition: "Article d'imposition incorrect" }))
        } else {
            seterrors((prev) => ({ ...prev, Articledimposition: '' }))
        }



        if (attestationdimmatriculationduNIF) {
            if (attestationdimmatriculationduNIF.size > 2097152) {
                seterrors((prev) => ({ ...prev, attestationdimmatriculationduNIF: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, attestationdimmatriculationduNIF: '' }))
            }
        } else {
            seterrors((prev) => ({ ...prev, attestationdimmatriculationduNIF: '' }))
        }



        if (copiedelapiécedidentité) {
            if (copiedelapiécedidentité.size > 2097152) {
                seterrors((prev) => ({ ...prev, copiedelapiécedidentité: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, copiedelapiécedidentité: '' }))
            }
        } else {
            seterrors((prev) => ({ ...prev, copiedelapiécedidentité: '' }))

        }

        if (extraitduregistreducommerce) {
            if (extraitduregistreducommerce.size > 2097152) {
                seterrors((prev) => ({ ...prev, extraitduregistreducommerce: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, extraitduregistreducommerce: '' }))
            }
        } else {
            seterrors((prev) => ({ ...prev, extraitduregistreducommerce: '' }))
        }



    }

    useEffect(() => {

        Validate();

    }, [Physiqueouentreprise, NomBoutiques, Tel, TelAdminboutique, Email, NomAdminBoutique, PrenomAdminBoutique, nomdupropriétairedelentreprise, Prenomdupropriétairedelentreprise, Date, Addrese, EmailParrain, secteurdactivité, Typedepiéce, Nif, Nrc, Tva, BoutiquesPlusieurs, Wilaya, Daira, Commune, CodePostal, Wilayadenaissence, Communedenaissence, attestationdimmatriculationduNIF, copiedelapiécedidentité, immatriculationauregistreducommerce, extraitduregistreducommerce, Articledimposition])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])


    useEffect(() => {

        const GetCommunedenaissence = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilayadenaissence}`);


                setCommunesdenaissence(response['communes'])
                // setCommunedenaissence('')

                if (!CommuneDeNaissanceMount.current) {
                    setCommunedenaissence('')
                } else {
                    // setCommune(Local?.communen || '')
                    CommuneDeNaissanceMount.current = false
                }

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilayadenaissence !== '') {
            GetCommunedenaissence();
        }


    }, [Wilayadenaissence])


    useEffect(() => {

        const GetDaira = async () => {
            try {

                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/dairas?valeur=${Wilaya}`);

                // setCommunes([])
                // setCommune('')
                // setCodePostals([])
                // setCodePostal('')

                setDairas(response['codepostals'])
                if (!DairaMount.current) {
                    setDaira('')
                    setCommunes([])
                    setCommune('')
                    setCodePostals([])
                    setCodePostal('')
                } else {
                    // setCommune(Local?.communen || '')
                    DairaMount.current = false
                }

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilaya !== '') {
            GetDaira();
        }


    }, [Wilaya])


    useEffect(() => {

        const GetCommunes = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/commune?valeur=${Daira}`);


                // setCodePostals([])
                // setCodePostal('')


                setCommunes(response['codepostals'])

                if (!CommuneMount.current) {
                    
                    setCommune('')
                    setCodePostals([])
                    setCodePostal('')
                } else {
                    // setCommune(Local?.communen || '')
                    CommuneMount.current = false
                }

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Daira !== '') {
            GetCommunes();
        }


    }, [Daira])

    useEffect(() => {

        const GetCodePostal = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/codepostal?valeur=${Commune}`);
                // console.log(response);
                // setCodePostal('')
                setCodePostals(response['codepostals'])

                if (!PostalCodeMount.current) {
                    setCodePostal('')
                  }else{
                    
                    // setCodePostal(Local?.codepostal || '')
                    PostalCodeMount.current = false
          
                  }





            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Commune !== '') {
            GetCodePostal();
        }


    }, [Commune])

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    const [Form, setForm] = useState([
        {
            "type_vendeur": Physiqueouentreprise,
            "nom_boutique": NomBoutiques,
            "tel": Tel,
            "email_admin": Email,
            "password": Password,
            "nom_admin": NomAdminBoutique,
            "prenom_admin": PrenomAdminBoutique,
            "tel_admin": TelAdminboutique,
            "email_parrain": EmailParrain,
            "nom_prop": nomdupropriétairedelentreprise,
            "prenom_prop": Prenomdupropriétairedelentreprise,
            "date_naissance": Date,
            "wilaya_naissance": Wilayadenaissence,
            "commune_naissance": Communedenaissence,
            "wilaya": Wilaya,
            "dairas": Daira,
            "commune": Commune,
            "code_postal": CodePostal,
            "adresse": Addrese,
            "nin": immatriculationauregistreducommerce,
            "nrc": Nrc,
            "nif": Nif,
            "article_imposition": Articledimposition,
            "tva": Tva,
            'type_piece': Typedepiéce,
            "wilaya_naissance": Wilayadenaissence,
            "commune_naissance": Communedenaissence,
            "secteur_activite": secteurdactivité,
            "piece_idnt": { "key": "piece_idnt", "file": copiedelapiécedidentité || null, "filename": copiedelapiécedidentité?.name || null },
            "rc_file": { "key": "rc_file", "file": extraitduregistreducommerce || null, "filename": extraitduregistreducommerce?.name || null },
            "nif_file": { "key": "nif_file", "file": attestationdimmatriculationduNIF || null, "filename": attestationdimmatriculationduNIF?.name || null }
        }

    ]);

    useEffect(() => {

        setForm([
            {
                "type_vendeur": Physiqueouentreprise,
                "nom_boutique": NomBoutiques,
                "tel": Tel,
                "email_admin": Email,
                "password": Password,
                "nom_admin": NomAdminBoutique,
                "prenom_admin": PrenomAdminBoutique,
                "tel_admin": TelAdminboutique,
                "email_parrain": EmailParrain,
                "nom_prop": nomdupropriétairedelentreprise,
                "prenom_prop": Prenomdupropriétairedelentreprise,
                "date_naissance": Date,
                "wilaya_naissance": Wilayadenaissence,
                "commune_naissance": Communedenaissence,
                "wilaya": Wilaya,
                "dairas": Daira,
                "commune": Commune,
                "code_postal": CodePostal,
                "adresse": Addrese,
                "nin": immatriculationauregistreducommerce,
                "nrc": Nrc,
                "nif": Nif,
                "article_imposition": Articledimposition,
                "tva": Tva,
                'type_piece': Typedepiéce,
                "wilaya_naissance": Wilayadenaissence,
                "commune_naissance": Communedenaissence,
                "secteur_activite": secteurdactivité,
                "piece_idnt": { "key": "piece_idnt", "file": copiedelapiécedidentité || null, "filename": copiedelapiécedidentité?.name || null },
                "rc_file": { "key": "rc_file", "file": extraitduregistreducommerce || null, "filename": extraitduregistreducommerce?.name || null },
                "nif_file": { "key": "nif_file", "file": attestationdimmatriculationduNIF || null, "filename": attestationdimmatriculationduNIF?.name || null }
            }
        ])

        // setAllData(getFormData(Form[0]))

    }, [Physiqueouentreprise, NomBoutiques, Tel, TelAdminboutique, Email, NomAdminBoutique, PrenomAdminBoutique, Password, RePassword, Typedepiéce, Addrese, immatriculationauregistreducommerce, Nif, Nrc, Articledimposition, Tva, secteurdactivité, EmailParrain, extraitduregistreducommerce, nomdupropriétairedelentreprise, Prenomdupropriétairedelentreprise, Date, Wilayadenaissence, Wilaya, Daira, Commune, Communedenaissence, CodePostal, copiedelapiécedidentité, attestationdimmatriculationduNIF])

    console.log(Form);


    function GoNext() {
        // setShowerr(true)

        // console.log(FormValidate);

        // if (!FormValidate) {
        //   return
        // }


        setShowerrors(true)

        if (errors.Physiqueouentreprise !== '' || errors.NomAdminBoutique !== '' || errors.NomBoutiques !== '' || errors.PrenomAdminBoutique !== '' || errors.TelAdminboutique !== '' || errors.Tel !== '' || errors.Email !== '' || errors.Password !== '' || errors.rePassword !== '', errors.nomdupropriétairedelentreprise !== '' || errors.Prenomdupropriétairedelentreprise !== '' || errors.Date !== '' || errors.Addrese !== '' || errors.EmailParrain !== '' || errors.secteurdactivité !== '' || errors.Typedepiéce !== '' || errors.Nif !== '' || errors.Nrc !== '' || errors.Tva !== '' || errors.BoutiquesPlusieurs !== '' || errors.Wilaya !== '' || errors.Daira !== '' || errors.Commune !== '' || errors.CodePostal !== '' || errors.Wilayadenaissence !== '' || errors.Communedenaissence !== '' || errors.immatriculationauregistreducommerce !== '' || errors.Articledimposition || errors.attestationdimmatriculationduNIF !== '' || errors.copiedelapiécedidentité !== '' || errors.extraitduregistreducommerce !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }

        console.log(AllData);



        for (var pair of AllData.entries()) {
            console.log(pair[0] + ' - ' + pair[1]);
        }

        let Data = getFormData(Form[0]);

        setLoading(true)

        console.log(Data);

        axios.put(`${process.env.REACT_APP_LOCAL_URL}/vendeur/update_vendeur/${id}`, Data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {

                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }

                // if (response.data['email'] && response.data['username'] && response.data['token']) {
                //     console.log(response.data['email'] , response.data['username'] , response.data['token']);
                //     setAuth({email : response.data['email'] , username : response.data['username'] , token :response.data['token']})
                //     setloading(false)
                //     return navigate('/')
                // }

                // console.log(response.data['creditDemandes'][0]);
                // console.log(response.data['creditDemandes'][0]['hash']);

                console.log(response.data);
                setLoading(false)

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Les données ont été modifiées avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location = "/listvendeuradmin";
                    });
                }


                else {


                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });
                }


                // if (response.data['creditDemandes'][0]['hash']) {
                //   return navigate('/validation/' + Formdata[0]['hash'])

                // } else {
                //   setWrong('Il y a un problème avec les données que vous avez saisies, veuillez réessayer')
                //   console.log(response.data);
                // }

                // setloading(false)
                // console.log(response.data);

            }).catch(function (error) {
                // setLoading(false)
                setLoading(false)

                console.log(error);
            });







        // console.log('test', FormValidate);
    }







    const [Titreduproduit, setTitreduproduit] = useState('')
    const [Réferenceduproduit, setRéferenceduproduit] = useState('')
    const [Marque, setMarque] = useState('')
    const [Catégorie, setCatégorie] = useState('')
    const [Quantité, setQuantité] = useState('')
    const [Quantitéunit, setQuantitéunit] = useState('')
    const [Datedébutdevente, setDatedébutdevente] = useState('')
    const [Datefindevente, setDatefindevente] = useState('')
    const [Prix, setPrix] = useState('')
    const [Conditionderetour, setConditionderetour] = useState('')
    const [Délaisdannulation, setDélaisdannulation] = useState('')
    const [Promo, setPromo] = useState(false)
    const [Prixpromotionnel, setPrixpromotionnel] = useState('')
    const [Datedébutpromotion, setDatedébutpromotion] = useState('')
    const [Datefinpromotion, setDatefinpromotion] = useState('')
    const [Caractéristiques, setCaractéristiques] = useState('')
    const [Description, setDescription] = useState('')
    const [Shortdescription, setShortdescription] = useState('')
    const [Image1, setImage1] = useState('')
    const [Image2, setImage2] = useState('')
    const [Image3, setImage3] = useState('')
    const [Image4, setImage4] = useState('')
    const [Image5, setImage5] = useState('')



    const [preview1, setPreview1] = useState()
    const [preview2, setPreview2] = useState()
    const [preview3, setPreview3] = useState()
    const [preview4, setPreview4] = useState()
    const [preview5, setPreview5] = useState()

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    console.log(Promo);

    const [Loading, setLoading] = useState(false)






    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        if (errors.Titreduproduit !== '' || errors.Réferenceduproduit !== '' || errors.Marque !== '' || errors.Catégorie !== '' || errors.Quantité !== '' || errors.Quantitéunit !== ''
            || errors.Datedébutdevente !== '' || errors.Datefindevente !== '' || errors.Prix !== '' || errors.Conditionderetour !== '' || errors.Caractéristiques !== '' || errors.Description !== '' || errors.Shortdescription !== ''
            || errors.Prixpromotionnel !== '' || errors.Datedébutpromotion !== '' || errors.Datefinpromotion !== ''
        ) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 60);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }



        const formData = new FormData();
        formData.append("titre_produit", Titreduproduit);
        formData.append("ref_produit", Réferenceduproduit);
        formData.append("marque", Marque);
        formData.append("id_categorie", Catégorie);
        formData.append("qte", Quantité);
        formData.append("qte_unit", Quantitéunit);
        formData.append("debut_miseVente", Datedébutdevente);
        formData.append("fin_miseVente", Datefindevente);
        formData.append("prix", Prix);
        formData.append("condition_retour", Conditionderetour);

        // formData.append("selectedFile", Délaisdannulation);
        formData.append("promo", Promo == true ? 1 : 0);
        formData.append("prix_promo", Prixpromotionnel);
        formData.append("debut_promo", Datedébutpromotion);
        formData.append("fin_promo", Datefinpromotion);
        formData.append("caracteristiques", Caractéristiques);
        formData.append("description", Description);
        formData.append("ShortDescription", Shortdescription);
        formData.append("images", Image1);
        formData.append("images", Image2);
        formData.append("images", Image3);
        formData.append("images", Image4);
        formData.append("images", Image5);







        // try {
        //   const response = await axios.post({
        //     method: "post",
        //     url: "${process.env.REACT_APP_LOCAL_URL}/vente/new_vente/11112222",
        //     data: formData,
        //     headers: { 
        //         "Content-Type": "multipart/form-data" ,
        //          Authorization: `Bearer ${Auth?.token}` 
        //     },
        //   });

        //   console.log(response);
        // } catch(error) {
        //   console.log(error)
        // }

        setLoading(true)


        axios.put(`${process.env.REACT_APP_LOCAL_URL}/vente/update_vente/${ProductData?.id_boutique}/${ProductData?.id_vente}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)
                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Les données ont été modifiées avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location = "/listvendeuradmin";
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }


    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];








    return (
        <div className='dashboard'>

            {Loading && 
            <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Modifier Le Vendeur <span>Store</span></div>
                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        {/* <div className='form-store'> */}
                        <form className="row g-5">
                            <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (

                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }

                            </div>
                            <div className="col-md-12">
                                <div className="col-md-12 text-center mb-3">
                                    <div className='mb-2'>vous êtes une personne physique ou une entreprise ? </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio18" checked={Physiqueouentreprise == 'Physique'} onChange={(e) => setPhysiqueouentreprise(e.target.value)} defaultChecked={true} defaultValue="Physique" />
                                        <label className="form-check-label" htmlFor="inlineRadio18">Physique</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio28" checked={Physiqueouentreprise == 'Entreprise'} onChange={(e) => setPhysiqueouentreprise(e.target.value)} defaultValue="Entreprise" />
                                        <label className="form-check-label" htmlFor="inlineRadio28">Entreprise</label>
                                    </div>
                                </div>
                                <div className='err-cart-sum'>{Showerrors && errors.Physiqueouentreprise !== '' ? errors.Physiqueouentreprise : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Nom de la boutique</label>
                                <input type="text" maxLength={300} onChange={(e) => setNomBoutiques(e.target.value)} value={NomBoutiques} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.NomBoutiques !== '' ? errors.NomBoutiques : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress" className="form-label">Nom de l'administrateur de la boutique ?</label>
                                <input type="text" maxLength={300} className="form-control" onChange={(e) => setNomAdminBoutique(e.target.value)} value={NomAdminBoutique} id="inputAddress" placeholder="" />
                                <div className='err-cart-sum'>{Showerrors && errors.NomAdminBoutique !== '' ? errors.NomAdminBoutique : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress" className="form-label">Prenom de l'administrateur de la boutique ?</label>
                                <input type="text" maxLength={300} className="form-control" onChange={(e) => setPrenomAdminBoutique(e.target.value)} value={PrenomAdminBoutique} id="inputAddress" placeholder="" />
                                <div className='err-cart-sum'>{Showerrors && errors.PrenomAdminBoutique !== '' ? errors.PrenomAdminBoutique : ''}</div>
                            </div>


                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress2" className="form-label">Numéro de téléphone de l'administrateur de la boutique ?</label>
                                <input type="tel" maxLength={12} onChange={(e) => setTelAdminboutique(e.target.value.replace(/[^0-9]+/g, ''))} value={TelAdminboutique} className="form-control" id="inputAddress2" placeholder="" />
                                <div className='err-cart-sum'>{Showerrors && errors.TelAdminboutique !== '' ? errors.TelAdminboutique : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress2" className="form-label">Numéro téléphone de la boutique</label>
                                <input type="tel" maxLength={12} onChange={(e) => setTel(e.target.value.replace(/[^0-9]+/g, ''))} value={Tel} className="form-control" id="inputAddress2" placeholder="" />
                                <div className='err-cart-sum'>{Showerrors && errors.Tel !== '' ? errors.Tel : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputCity" className="form-label">Email</label>
                                <input type="email" maxLength={300} onChange={(e) => setEmail(e.target.value.replace(/[^a-zA-Z@.0-9]+/g, ''))} value={Email} className="form-control" id="inputCity" />
                                <div className='err-cart-sum'>{Showerrors && errors.Email !== '' ? errors.Email : ''}</div>
                            </div>
                            {/* <div className="col-md-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputState" className="form-label">Mot de Passe</label>
                        <input type="password" onChange={(e) => setPassword(e.target.value)} value={Password} className="form-control" id="inputCity" />
                        <div className='err-cart-sum'>{Showerrors && errors.Password !== '' ? errors.Password : ''}</div>
                    </div> */}
                            {/* <div className="col-md-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                            </g>
                        </svg>
                        <label htmlFor="inputState" className="form-label">Répéter le mot de passe</label>
                        <input type="password" onChange={(e) => setRePassword(e.target.value)} value={RePassword} className="form-control" id="inputCity" />
                        <div className='err-cart-sum'>{Showerrors && errors.rePassword !== '' ? errors.rePassword : ''}</div>

                    </div> */}
                            <div className="col-md-6 mt-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Parrainé par (email du parrain)</label>
                                <input type="email" maxLength={300} onChange={(e) => setEmailParrain(e.target.value.replace(/[^a-zA-Z@.-0-9]+/g, ''))} value={EmailParrain} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.EmailParrain !== '' ? errors.EmailParrain : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <div className='d-flex'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputAddress" className="form-label">nom du propriétaire de l'entreprise (Gérant , Directeur Général , Cadre Supérieur)</label>
                                </div>
                                <input type="text" maxLength={300} className="form-control" onChange={(e) => setnomdupropriétairedelentreprise(e.target.value)} value={nomdupropriétairedelentreprise} id="inputAddress" placeholder="" />
                                <div className='err-cart-sum'>{Showerrors && errors.nomdupropriétairedelentreprise !== '' ? errors.nomdupropriétairedelentreprise : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <div className='d-flex'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputAddress" className="form-label">Prénom du propriétaire de l'entreprise (Gérant , Directeur Général , Cadre Supérieur)</label>
                                </div>
                                <input type="text" maxLength={300} className="form-control" onChange={(e) => setPrenomdupropriétairedelentreprise(e.target.value)} value={Prenomdupropriétairedelentreprise} id="inputAddress" placeholder="" />
                                <div className='err-cart-sum'>{Showerrors && errors.Prenomdupropriétairedelentreprise !== '' ? errors.Prenomdupropriétairedelentreprise : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress2" className="form-label">Date de naissance du propriétaire de l'entreprise</label>
                                <input type="date" className="form-control" onChange={(e) => setDate(e.target.value)} value={Date} id="inputAddress2" placeholder="Apartment, studio, or floor" />
                                <div className='err-cart-sum'>{Showerrors && errors.Date !== '' ? errors.Date : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputCity" className="form-label">secteur d'activité</label>
                                <input type="text" maxLength={300} className="form-control" onChange={(e) => setsecteurdactivité(e.target.value)} value={secteurdactivité} id="inputCity" />
                                <div className='err-cart-sum'>{Showerrors && errors.secteurdactivité !== '' ? errors.secteurdactivité : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputCity" className="form-label">Adresse</label>
                                <input type="text" maxLength={300} className="form-control" onChange={(e) => setAddrese(e.target.value)} value={Addrese} id="inputCity" />
                                <div className='err-cart-sum'>{Showerrors && errors.Addrese !== '' ? errors.Addrese : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Wilaya de Naissence </label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setWilayadenaissence(e.target.value)}>
                                    <option disabled selected={Wilayadenaissence == "" ? "selected" : ''}>Wilaya de Naissence</option>
                                    <label htmlFor="inputState" className="form-label">Wilaya de Naissence</label>
                                    {WilayaList.length > 0 && WilayaList.map((item, i) => (

                                        <option selected={Wilayadenaissence == item?.value} value={item.value}>{item.text}</option>

                                    ))
                                    }

                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Wilayadenaissence !== '' ? errors.Wilayadenaissence : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Commune de Naissence</label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setCommunedenaissence(e.target.value)}>
                                    {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
                                    <option disabled selected={Communedenaissence == "" ? "selected" : ''}>Commune de Naissence</option>
                                    {/* } */}


                                    {Communesdenaissence.length > 0 && Communesdenaissence.map((item, i) => (
                                        <option selected={Communedenaissence == item?.commune} value={item?.commune}>{item?.commune}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Communedenaissence !== '' ? errors.Communedenaissence : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Wilaya</label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setWilaya(e.target.value)}>
                                    <option disabled selected={Wilaya == "" ? "selected" : ''}>Wilaya</option>
                                    <label htmlFor="inputState" className="form-label">Wilaya</label>
                                    {WilayaList.length > 0 && WilayaList.map((item, i) => (
                                        <option selected={Wilaya == item?.value} value={item.value}>{item.text}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Wilaya !== '' ? errors.Wilaya : ''}</div>

                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Daira</label>

                                <select class="form-select" aria-label="Default select example" onChange={(e) => setDaira(e.target.value)}>
                                    {/* <option disabled selected={Daira == "" ? "selected" : ''}>Daira</option> */}
                                    <label htmlFor="inputState" className="form-label">Daira</label>
                                    {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
                                    <option disabled selected={Daira == "" ? "selected" : ''}>Daira</option>
                                    {/* } */}


                                    {Dairas.length > 0 && Dairas.map((item, i) => (
                                        <option selected={Daira == item?.dairas} value={item?.dairas}>{item?.dairas}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Daira !== '' ? errors.Daira : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Commune</label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>
                                    <option disabled selected={Commune == "" ? "selected" : ''}>Commune</option>
                                    <label htmlFor="inputState" className="form-label">Commune</label>
                                    {Communes.length > 0 && Communes.map((item, i) => (
                                        <option selected={Commune == item?.commune} value={item?.commune}>{item?.commune}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Commune !== '' ? errors.Commune : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Code Postal</label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setCodePostal(e.target.value)}>
                                    <option disabled selected={CodePostal == "" ? "selected" : ''}>Code Postal</option>
                                    <label htmlFor="inputState" className="form-label">Code Postal</label>
                                    {CodePostals.length > 0 && CodePostals.map((item, i) => (
                                        <option selected={CodePostal == item?.codePostal} value={item?.codePostal}>{item?.codePostal}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.CodePostal !== '' ? errors.CodePostal : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <div className='d-flex'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputState" className="form-label">Type de piéce d'identité du propriétaire de l'entreprise (Gérant , Directeur Général , Cadre Supérieur)</label>
                                </div>
                                <input type="text" maxLength={300} className="form-control" id="inputCity" onChange={(e) => setTypedepiéce(e.target.value)} value={Typedepiéce} />
                                <div className='err-cart-sum'>{Showerrors && errors.Typedepiéce !== '' ? errors.Typedepiéce : ''}</div>

                            </div>


                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Numéro d'immatriculation au registre du commerce</label>
                                <input type="text" maxLength={25} onChange={(e) => setimmatriculationauregistreducommerce(e.target.value)} value={immatriculationauregistreducommerce} className="form-control" id="inputCity" />
                                <div className='err-cart-sum'>{Showerrors && errors.immatriculationauregistreducommerce !== '' ? errors.immatriculationauregistreducommerce : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Numéro d'identification fiscale (nif)</label>
                                <input type="tel" maxLength={25} onChange={(e) => setNif(e.target.value.replace(/[^0-9]+/g, ''))} value={Nif} className="form-control" id="inputCity" />
                                <div className='err-cart-sum'>{Showerrors && errors.Nif !== '' ? errors.Nif : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Centre national du registre de commerce NRC</label>
                                <input type="text" maxLength={30} onChange={(e) => setNrc(e.target.value)} value={Nrc} className="form-control" id="inputCity" />
                                <div className='err-cart-sum'>{Showerrors && errors.Nrc !== '' ? errors.Nrc : ''}</div>

                            </div>



                            <div className="col-md-6">
                                <div className='d-flex'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputState" className="form-label">Veuillez télécharger une copie de l'extrait du registre du commerce </label>
                                </div>
                                <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setextraitduregistreducommerce(e.target.files[0]) : setextraitduregistreducommerce('')} className="form-control" id="inputCity" />
                                <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${Oldextraitduregistreducommerce}`}> Voir le dossier en cours </a>
                                <div className='err-cart-sum'>{Showerrors && errors.extraitduregistreducommerce !== '' ? errors.extraitduregistreducommerce : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <div className='d-flex'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputState" className="form-label">Veuillez télécharger une copie de la piéce d'identité du propriétaire de l'entreprise</label>
                                </div>
                                <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setcopiedelapiécedidentité(e.target.files[0]) : setcopiedelapiécedidentité('')} className="form-control" id="inputCity" />
                                <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${Oldcopiedelapiécedidentité}`}> Voir le dossier en cours </a>
                                <div className='err-cart-sum'>{Showerrors && errors.copiedelapiécedidentité !== '' ? errors.copiedelapiécedidentité : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Télécharger l'attestation d'immatriculation du NIF</label>
                                <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setattestationdimmatriculationduNIF(e.target.files[0]) : setattestationdimmatriculationduNIF('')} className="form-control" id="inputCity" />
                                <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldattestationdimmatriculationduNIF}`}> Voir le dossier en cours </a>

                                <div className='err-cart-sum'>{Showerrors && errors.attestationdimmatriculationduNIF !== '' ? errors.attestationdimmatriculationduNIF : ''}</div>

                            </div>
                            {/* <div className="col-md-6">
                        <label htmlFor="inputState" className="form-label">ETES-vous assujetti à la tva ?</label>
                        <input type="text" className="form-control" id="inputCity" />
                    </div> */}
                            <div className="col-md-6 text-center mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <div className='mb-2'>ETES-vous assujetti à la tva ?</div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio199" checked={Tva == 'oui'} onChange={(e) => setTva(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                    <label className="form-check-label" htmlFor="inlineRadio199">Oui</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio299" checked={Tva == 'non'} onChange={(e) => setTva(e.target.value)} defaultValue="non" />
                                    <label className="form-check-label" htmlFor="inlineRadio299">Non</label>
                                </div>
                                <div className='err-cart-sum'>{Showerrors && errors.Tva !== '' ? errors.Tva : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputState" className="form-label">Article d'imposition</label>
                                <input type="text" maxLength={30} className="form-control" onChange={(e) => setArticledimposition(e.target.value)} value={Articledimposition} id="inputCity" />
                                <div className='err-cart-sum'>{Showerrors && errors.Articledimposition !== '' ? errors.Articledimposition : ''}</div>

                            </div>

                            <div className="col-md-6 text-center mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <div className='mb-2'>avez vous deja une boutique ou plusieurs boutiques sur diar dzair ?</div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio1" checked={BoutiquesPlusieurs == 'oui'} onChange={(e) => setBoutiquesPlusieurs(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Oui</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio2" checked={BoutiquesPlusieurs == 'non'} onChange={(e) => setBoutiquesPlusieurs(e.target.value)} defaultValue="non" />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Non</label>
                                </div>
                                <div className='err-cart-sum'>{Showerrors && errors.BoutiquesPlusieurs !== '' ? errors.BoutiquesPlusieurs : ''}</div>

                            </div>

                            <div className="col-12 mt-5">
                                <div onClick={() => GoNext()} className="store-next-btn">Modifier</div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

        // </div>
    )
}

export default UpdateVendeur