import { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Spinner from '../Global/Spinner';


const SearchData = ({ SearchKey, setSearchKey, Category, setCategory, Cat, setCat, Wilaya, setWilaya, WilayaS, setWilayaS }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const key = searchParams.get('key') ? searchParams.get('key') : '';
    const page = searchParams.get('page') ? searchParams.get('page') : '1';
    const categorie = searchParams.get('categorie') ? searchParams.get('categorie') : '';
    const wilaya = searchParams.get('wilaya') ? searchParams.get('wilaya') : '';



    const [StoreBoutiques, setStoreBoutiques] = useState([])
    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(true)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const keyquery = key.trim() ? `&key=${key}` : '';
        const pagequery = page.trim() ? `?page=${page}` : '?page=1';
        const wilayaquery = wilaya.trim() ? `&wilaya=${wilaya}` : '';
        const categoriequery = categorie.trim() ? `&categorie=${categorie}` : '';



        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/login/search${pagequery}${keyquery}${wilayaquery}${categoriequery}`);
                // console.log(response[0]);
                setStoreBoutiques(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                console.log(response);
                setLoading(false)

                // console.log(DarTaksitCat)

            } catch (error) {
                setLoading(false)
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    console.log(CurrentPage, PageNumber);


    return (
        <>
            <div className="store-boutiques mt-2 mt-lg-5 mb-2 pb-2">
                <div className="container">
                    {/* <div className="store-cat-head">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="26.572" height="25" viewBox="0 0 26.572 25"><path id="Mask" d="M21.248,25A2.084,2.084,0,1,1,23.3,22.916,2.072,2.072,0,0,1,21.248,25ZM7.54,25A2.084,2.084,0,1,1,9.6,22.916,2.073,2.073,0,0,1,7.54,25Zm13.024-6.945H8.226A1.38,1.38,0,0,1,6.9,17.032L3.065,2.777H1.371A1.381,1.381,0,0,1,0,1.389,1.381,1.381,0,0,1,1.371,0H4.112A1.381,1.381,0,0,1,5.436,1.024l.845,3.143H23.828A2.714,2.714,0,0,1,26.16,5.484a2.793,2.793,0,0,1,.121,2.7l-4.491,9.1A1.363,1.363,0,0,1,20.563,18.055Z" fill="#363c5a" /></svg>
                        Nos Boutiques</p>
                     
                </div> */}
                    <div className="row">
                        {StoreBoutiques?.length > 0 && StoreBoutiques?.map((item, i) => (



                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="boutique-card">
                                    <a href={`/product/${item?.boutique_slug}/${item?.vente_slug}`}>
                                        <img className="img-fluid img-b-bg" src={item?.image1 !== null && item?.image1 !== 'undefined' ? `${process.env.REACT_APP_LOCAL_URL}/images/ventes/${item?.image1}` : 'https://www.orbis.com.ar/wp-content/themes/barberry/images/placeholder.jpg'} />
                                        <div className="boutique-content">
                                            <div className="boutique-desc">
                                                <p className="boutique-title">{item?.titre_produit}</p>
                                                <p className="boutique-map">{item?.wilaya}</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href={'/boutique/' + item?.boutique_slug}>
                                    <div className="boutique-content-store">
                                        <div className="boutique-desc-store">
                                            <p className="boutique-title-store">{item?.nom}</p>
                                        </div>
                                        <div className="boutique-logo-store">
                                            <img className="img-fluid" src={item?.logo !== null && item?.logo !== 'undefined' ? `${process.env.REACT_APP_LOCAL_URL}/images/boutiques/logo/${item?.logo}` : 'https://www.orbis.com.ar/wp-content/themes/barberry/images/placeholder.jpg'} />
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>

                        ))
                        }

                        {Loading ? <Spinner /> : '' } 

                        {StoreBoutiques?.length == 0 && !Loading && <div className='store-search-empty'>Pas de résultats</div>}









                    </div>
                </div>
            </div>
            <div>
                {PageNumber > 1 &&
                    <div class="text-center mt-2 pt-1 mb-5">
                        <div class={`pagination ${Loading && 'd-none'} ${CurrentPage} ${PageNumber} ${CurrentPage < PageNumber}`}>
                            {CurrentPage > 1 ? <a href={`search?page=1${key.trim() ? `&key=${key}` : ''}${categorie.trim() ? `&categorie=${categorie}` : ''}${wilaya.trim() ? `&wilaya=${wilaya}` : ''}`}  >{'<<'}</a> : <a>{'<<'}</a>}

                            {CurrentPage !== 1 ? <a href={`search?page=${parseInt(CurrentPage) - 1}${key.trim() ? `&key=${key}` : ''}${categorie.trim() ? `&categorie=${categorie}` : ''}${wilaya.trim() ? `&wilaya=${wilaya}` : ''}`}>&laquo;</a> : <a>&laquo;</a>}



                            {PageNumber > 0 && [...Array(PageNumber).keys()].map((item, i) => (

                                <>
                                    {(item + 1 == (parseInt(CurrentPage) + 1) || item + 1 == (parseInt(CurrentPage) + 2) || item + 1 == (parseInt(CurrentPage) - 1) || item + 1 == (parseInt(CurrentPage) - 2) || item + 1 == CurrentPage) ?
                                        <a href={`search?page=${item + 1}${key.trim() ? `&key=${key}` : ''}${categorie.trim() ? `&categorie=${categorie}` : ''}${wilaya.trim() ? `&wilaya=${wilaya}` : ''}`} className={item + 1 == CurrentPage ? 'active' : ''}  >{item + 1}</a>
                                        : ''}
                                </>

                            ))}



                            {CurrentPage < PageNumber ? <a href={`search?page=${parseInt(CurrentPage) + 1}${key.trim() ? `&key=${key}` : ''}${categorie.trim() ? `&categorie=${categorie}` : ''}${wilaya.trim() ? `&wilaya=${wilaya}` : ''}`}>&raquo;</a> : <a>&raquo;</a>}

                            {CurrentPage < PageNumber ? <a href={`search?page=${PageNumber}${key.trim() ? `&key=${key}` : ''}${categorie.trim() ? `&categorie=${categorie}` : ''}${wilaya.trim() ? `&wilaya=${wilaya}` : ''}`}  >{'>>'}</a> : <a>{'>>'}</a>}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default SearchData