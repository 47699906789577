import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardUser/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import LoadData from '../../components/Global/LoadData';

const VendeurVoirUser = () => {

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';

    let { id } = useParams();

    id = 'user';




    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data'][0]);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                setLoading(false);
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    function Modifier() {
        Swal.fire({
            // title: 'Êtes-vous sûr',
            html: "Pour modifier vos données, veuillez contacter le Call Center Diar Dzair<br> <b>09 82 400 500</b>",
            icon: 'warning',
            confirmButtonText: 'Ok !',

            // reverseButtons: true
        })

    }


    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Mes Information<span>Store</span></div>

                    <div className='new-admin-btn' onClick={Modifier}> Modifier mes informations </div>

                    {Loading ? <LoadData /> : ''}

                    {!Loading && ListVentes?.length != 0 ? 
                    <>

                    <div className="conatiner mb-5">
                        <ul className="list-group list-store-admin">
                            <li className={`list-group-item active gradiant`} aria-current="true">Information Generale</li>
                            {ListVentes?.nom_prop ? <li className="list-group-item"> <b>Nom du propriétaire de l'entreprise </b>: {ListVentes?.nom_prop}</li> : ''}
                            {ListVentes?.prenom_prop ? <li className="list-group-item"> <b>Prénom du propriétaire de l'entreprise </b>: {ListVentes?.prenom_prop}</li> : ''}
                            {ListVentes?.email_parrain ? <li className="list-group-item"><b> Parrainé par (email du parrain) </b>: {ListVentes?.email_parrain}</li> : ''}
                            {ListVentes?.date_naissance ? <li className="list-group-item"><b> Date de naissance du propriétaire de l'entreprise </b>: {ListVentes?.date_naissance}</li> : ''}
                            {ListVentes?.wilaya_naissance ? <li className="list-group-item"><b> Wilaya de Naissence </b>: {ListVentes?.wilaya_naissance}</li> : ''}
                            {ListVentes?.commune_naissance ? <li className="list-group-item"><b> Commune de Naissence </b>: {ListVentes?.commune_naissance}</li> : ''}
                            {ListVentes?.wilaya ? <li className="list-group-item"><b> Wilaya </b>: {ListVentes?.wilaya}</li> : ''}
                            {ListVentes?.dairas ? <li className="list-group-item"><b> Daira </b>: {ListVentes?.dairas}</li> : ''}
                            {ListVentes?.commune ? <li className="list-group-item"><b> Commune </b>: {ListVentes?.commune}</li> : ''}
                            {ListVentes?.code_postal ? <li className="list-group-item"><b> Code Postal </b>: {ListVentes?.code_postal}</li> : ''}
                            {ListVentes?.adresse ? <li className="list-group-item"><b> Adresse </b>: {ListVentes?.adresse}</li> : ''}
                            {ListVentes?.nin ? <li className="list-group-item"><b> Numéro d'immatriculation au registre du commerce </b>: {ListVentes?.nin}</li> : ''}
                            {ListVentes?.nrc ? <li className="list-group-item"><b> Centre national du registre de commerce NRC </b>: {ListVentes?.nrc}</li> : ''}
                            {ListVentes?.nif ? <li className="list-group-item"><b> Numéro d'identification fiscale (nif) </b>: {ListVentes?.nif}</li> : ''}
                            {ListVentes?.article_imposition ? <li className="list-group-item"><b> Article d'imposition </b>: {ListVentes?.article_imposition}</li> : ''}
                            {ListVentes?.tva ? <li className="list-group-item"><b> ETES-vous assujetti à la tva ? </b>: {ListVentes?.tva}</li> : ''}
                            {ListVentes?.type_vendeur ? <li className="list-group-item"><b> vous êtes une personne physique ou une entreprise ? </b>: {ListVentes?.type_vendeur}</li> : ''}
                            {ListVentes?.tel ? <li className="list-group-item"><b> Tel </b>: {ListVentes?.tel}</li> : ''}
                            {ListVentes?.secteur_activite ? <li className="list-group-item"><b> Secteur d'activité </b>: {ListVentes?.secteur_activite}</li> : ''}
                            {ListVentes?.nom_boutique ? <li className="list-group-item"><b> Nom de la boutique </b>: {ListVentes?.nom_boutique}</li> : ''}
                            {ListVentes?.type_piece_idnt ? <li className="list-group-item"><b> Type de piéce d'identité </b>: {ListVentes?.type_piece_idnt}</li> : ''}
                            {ListVentes?.piece_idnt ? <li className="list-group-item"><b>Piéce d'identité </b>: <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${ListVentes?.piece_idnt}`}> Voir </a>  </li> : ''}
                            {ListVentes?.rc_file ? <li className="list-group-item"><b>Copie de l'extrait du registre du commerce </b>: <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${ListVentes?.rc_file}`}> Voir </a>  </li> : ''}
                            {ListVentes?.nif_file ? <li className="list-group-item"><b>l'attestation d'immatriculation du NIF </b>: <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${ListVentes?.nif_file}`}> Voir </a>  </li> : ''}
                        </ul>
                    </div>
                    <div className="conatiner mb-5">
                        <ul className="list-group list-store-admin">
                            <li className={`list-group-item active gradiant`} aria-current="true">Information Admin</li>
                            {ListVentes?.nom_admin ? <li className="list-group-item"> Nom de l'administrateur de la boutique : {ListVentes?.nom_admin}</li> : ''}
                            {ListVentes?.prenom_admin ? <li className="list-group-item"> Prenom de l'administrateur de la boutique : {ListVentes?.prenom_admin}</li> : ''}
                            {ListVentes?.tel_admin ? <li className="list-group-item"> Tél Admin : {ListVentes?.tel_admin}</li> : ''}
                            {ListVentes?.email_admin ? <li className="list-group-item"> Email Admin : {ListVentes?.email_admin}</li> : ''}
                        </ul>
                    </div>

                    </> : ''}


                </div>
            </div>


        </div>
    )
}

export default VendeurVoirUser