import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardUser/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



const UpdateBoutiqueUser = () => {

    const ComuneMount = useRef(true);


    const {id_btq} = useParams();
    const [BoutiqueData, setBoutiqueData] = useState('')

    const [IdBoutique, setIdBoutique] = useState('')


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/boutique/${id_btq}`);

                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response['data']);
                setBoutiqueData(response['data'])
                 

                // const [Nomdeboutique, setNomdeboutique] = useState('')
                // const [Email, setEmail] = useState('')
                // const [Adresse, setAdresse] = useState('')
                // const [Tel, setTel] = useState('')
                // const [Site, setSite] = useState('')
                // const [Facebook, setFacebook] = useState('')
                // const [Instagram, setInstagram] = useState('')
                // const [Heurededébut, setHeurededébut] = useState('')
                // const [Heuredefin, setHeuredefin] = useState('')
                // const [Validé, setValidé] = useState(false)
                // const [Description, setDescription] = useState('')

                setIdBoutique(response['data']['id_boutique'])


                setNomdeboutique(response['data']['nom'])
                setEmail(response['data']['email'])
                setAdresse(response['data']['adresse'])
                // setCatégorie(response['data']['marque'])
                setTel(String(response['data']['tel1']))
                // setDatedébutdevente(response['data'][''])
                setHeurededébut(response['data']['heurD'] || '')
                setHeuredefin(response['data']['heurF'] || '')
                setWilaya(response['data']['wilaya'] || '')
                setCommune(response?.data?.commune !== 'null' || 'undefined' ? response?.data?.commune : '');

                // setValidé(response['data']['valid'] == 1 ? true : false)
                // setTopAds(response['data']['top_ads'] == 1 ? true : false)




                setFacebook(response['data']['facebook'] || '')
                setInstagram(response['data']['instagram'] || '') 
                setSite(response['data']['site'] || '')
                setMap(response['data']['map'] || '')

                // setValidé(response['data']['valid'])
                setDescription(response['data']['description'])
                // setShortdescription(response['data']['ShortDescription'])

                if (response['data']['logo'] !== 'undefined' && response['data']['logo'] !== null) {                   
                setPreview1(`${process.env.REACT_APP_LOCAL_URL}/images/boutiques/logo/${response['data']['logo']}`)
                setImage1(true)
                setinitPreview1(true)
                }

                if (response['data']['bg'] !== 'undefined' && response['data']['bg'] !== null) {                   

                setPreview2(`${process.env.REACT_APP_LOCAL_URL}/images/boutiques/bg/${response['data']['bg']}`)
                setImage2(true)
                setinitPreview2(true)

                }




            } catch (error) {
            setLoading(false);

                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);

    const [Wilaya, setWilaya] = useState('')
    // const [WilayaList, setWilayaList] = useState([])
    // useEffect(() => {

    //     const fetchData = async () => {
    //         // setLoading(true);
    //         try {
    //             const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/wilaya`);
    //             // console.log(response[0]);
    //             setWilayaList(response['wilayas']);
    //             // console.log(DarTaksitCat)

    //         } catch (error) {
    //             console.error(error.message);
    //         }
    //         // setLoading(false);
    //     }

    //     fetchData();

    // }, []);

    const [Commune, setCommune] = useState('')
    const [CommuneList, setCommuneList] = useState([])

    useEffect(() => {

        const GetCommune = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilaya}`);


                setCommuneList(response['communes'])
                // setCommunedenaissence('')

                if (!ComuneMount.current) {
                    setCommune('')
                  } else {
                    // setCommune(Local?.communen || '')
                    ComuneMount.current = false
                  }

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilaya !== '') {
            GetCommune();
        }


    }, [Wilaya])

    //   useEffect(() => {

    //     const GetCommune = async () => {
    //       try {
    //         const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/region/dairas?valeur=${Wilaya}`);
    //         console.log(response);
    //         setCommuneList(response['codepostals'])
    //       } catch (error) {
    //         console.error(error.message);
    //       }
    //     }

    //     if (Wilaya !== '') {
    //       GetCommune();
    //     }



    //   }, [Wilaya])

    // console.log(Category);

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)





    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);





    // useEffect(() => {


    //     if (Auth) {
    //         console.log('hi!');

    //     } else {
    //         console.log('no!');
    //         return navigate("/");
    //     }

    //     console.log(Auth);


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Nomdeboutique, setNomdeboutique] = useState('')
    const [Email, setEmail] = useState('')
    const [Adresse, setAdresse] = useState('')
    const [Tel, setTel] = useState('')
    const [Site, setSite] = useState('')
    const [Map, setMap] = useState('')
    const [Facebook, setFacebook] = useState('')
    const [Instagram, setInstagram] = useState('')
    const [Heurededébut, setHeurededébut] = useState('')
    const [Heuredefin, setHeuredefin] = useState('')
    const [Validé, setValidé] = useState(false)
    const [TopAds, setTopAds] = useState(false)
    const [Description, setDescription] = useState('')
    const [Image1, setImage1] = useState('')
    const [Image2, setImage2] = useState('')



    const [preview1, setPreview1] = useState()
    const [preview2, setPreview2] = useState()
    const [initpreview1, setinitPreview1] = useState(false)
    const [initpreview2, setinitPreview2] = useState(false)


    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
      }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Nomdeboutique: '', Email: '', Adresse: '', Site: '', Facebook: '', Instagram: '', Heurededébut: '', Heuredefin: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)
    function Validate() {

        if (Nomdeboutique?.toString().trim().length > 300 || Nomdeboutique?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Nomdeboutique: 'Veuillez écrire le nom de la boutique !! (Max 300 Caractères)' }))
        } else {
            seterrors((prev) => ({ ...prev, Nomdeboutique: '' }))
        }

        if (Email?.toString().trim().length > 300 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
            seterrors((prev) => ({ ...prev, Email: "Veuillez écrire l'e-mail qui apparaît aux clients !! (Max 300 Caractères)" }))
        } else {
            seterrors((prev) => ({ ...prev, Email: '' }))
        }

        if (Adresse?.toString().trim().length > 600 || Adresse?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Adresse: 'Veuillez saisir la Adresse MAX(600 Caractères)' }))
        } else {
            seterrors((prev) => ({ ...prev, Adresse: '' }))
        }
        if (Tel?.toString().trim().length > 12 || Tel?.toString().trim().length < 9 || Tel?.toString().trim() == '' || !onlyNumbers(Tel)) {
            seterrors((prev) => ({ ...prev, Tel: 'Veuillez écrire un numéro de téléphone valide (Max 12) (Min 9)' }))
        } else {
            seterrors((prev) => ({ ...prev, Tel: '' }))
        }
        if (Wilaya?.toString().trim() == '' || Wilaya?.toString().trim().length > 600 ) {
            seterrors((prev) => ({ ...prev, Wilaya: 'Veuillez sélectionner votre wilaya' }))
        } else {
            seterrors((prev) => ({ ...prev, Wilaya: '' }))
        }

        if (Commune?.toString().trim() == '' || Commune?.toString().trim().length > 600 ) {
            seterrors((prev) => ({ ...prev, Commune: 'Commune incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Commune: '' }))
        }

        if (Facebook?.toString().trim() !== '') {
            if (Facebook?.toString().trim().length > 300 ) {
                seterrors((prev) => ({ ...prev, Facebook: 'Facebook Lien incorrect (Max 300 Caractères)' }))
            } else {
                seterrors((prev) => ({ ...prev, Facebook: '' }))
            }
            
        }else{
            seterrors((prev) => ({ ...prev, Facebook: '' }))
        }

        if (Instagram?.toString().trim() !== '') {
            if (Instagram?.toString().trim().length > 300 ) {
                seterrors((prev) => ({ ...prev, Instagram: 'Instagram Lien incorrect (Max 300 Caractères)' }))
            } else {
                seterrors((prev) => ({ ...prev, Instagram: '' }))
            }
            
        }else{
            seterrors((prev) => ({ ...prev, Instagram: '' }))
        }

        if (Site?.toString().trim() !== '') {
            if (Site?.toString().trim().length > 300 ) {
                seterrors((prev) => ({ ...prev, Site: 'Site Lien incorrect (Max 300 Caractères)' }))
            } else {
                seterrors((prev) => ({ ...prev, Site: '' }))
            }
            
        }else{
            seterrors((prev) => ({ ...prev, Site: '' }))
        }

        if (Map?.toString().trim() !== '') {
            if (Map?.toString().trim().length > 800 ) {
                seterrors((prev) => ({ ...prev, Map: 'Map Lien incorrect (Max 800 Caractères)' }))
            } else {
                seterrors((prev) => ({ ...prev, Map: '' }))
            }
            
        }else{
            seterrors((prev) => ({ ...prev, Map: '' }))
        }

        if (Image1) {
            if (Image1.size > 2097152 ) {
                seterrors((prev) => ({ ...prev, Image1: 'Le logo que vous avez choisi ne doit pas dépasser 2 Mo' }))
            } else {
                seterrors((prev) => ({ ...prev, Image1: '' }))
            }
            
        }else{
            seterrors((prev) => ({ ...prev, Image1: '' }))
        }

        if (Image2) {
            if (Image2.size > 4097152 ) {
                seterrors((prev) => ({ ...prev, Image2: 'La couverture que vous avez sélectionné ne doit pas dépasser 3 Mo' }))
            } else {
                seterrors((prev) => ({ ...prev, Image2: '' }))
            }
            
        }else{
            seterrors((prev) => ({ ...prev, Image2: '' }))
        }
        

        if (Description?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Description: 'Veuillez écrire une description de la boutique' }))
        } else {
            seterrors((prev) => ({ ...prev, Description: '' }))
        }


    }

    useEffect(() => {

        Validate();

    }, [Nomdeboutique, Tel, Email, Adresse,Wilaya,Commune,Facebook,Instagram,Site,Map, Heurededébut, Heuredefin, Description,Image1,Image2])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])


    useEffect(() => {

        if (Image1 == true) {
            return
        }
        if (!Image1) {
            setPreview1(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image1)
        setPreview1(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image1])


    useEffect(() => {
        if (Image2 == true) {
            return
        }
        if (!Image2) {
            setPreview2(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image2)
        setPreview2(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image2])






    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

         if (errors.Nomdeboutique !== '' || errors.Email !== '' || errors.Adresse !== '' || errors.Tel !== '' || errors.Site !== '' || errors.Map !== '' || errors.Facebook !== ''
         || errors.Heurededébut !== '' || errors.Heuredefin !== '' || errors.Instagram !== '' || errors.Description !== '' || errors.Wilaya !== '' || errors.Commune !== '' || errors.Image1 !== '' || errors.Image2 !== ''
        ) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 60);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }



        const formData = new FormData();
        formData.append("nom", Nomdeboutique);
        formData.append("email", Email);
        formData.append("adresse", Adresse);
        formData.append("wilaya", Wilaya);
        formData.append("commune", Commune);
        formData.append("heurD", Heurededébut);
        formData.append("heurF", Heuredefin);
        formData.append("site", Site);
        formData.append("map", Map);
        formData.append("facebook", Facebook);
        formData.append("instagram", Instagram);
        formData.append("tel1", Tel);
        formData.append("description", Description);
        formData.append("id_vendeur", 23456666);

        // formData.append("valid", Validé == true ? 1 : 0);
        // formData.append("topads", TopAds == true ? 1 : 0);
        formData.append("logo", Image1 || null);
        formData.append("bg", Image2 || null);

        // formData.append("selectedFile", Délaisdannulation);
        // formData.append("prix_promo", Prixpromotionnel);
        // formData.append("debut_promo", Datedébutpromotion);
        // formData.append("fin_promo", Datefinpromotion);
        // formData.append("caracteristiques", Caractéristiques);
        // formData.append("ShortDescription", Shortdescription);
        // formData.append("images", Image1);
        // formData.append("images", Image2);
        // formData.append("images", Image3);
        // formData.append("images", Image4);
        // formData.append("images", Image5);







        // try {
        //   const response = await axios.post({
        //     method: "post",
        //     url: "${process.env.REACT_APP_LOCAL_URL}/vente/new_vente/11112222",
        //     data: formData,
        //     headers: { 
        //         "Content-Type": "multipart/form-data" ,
        //          Authorization: `Bearer ${Auth?.token}` 
        //     },
        //   });

        //   console.log(response);
        // } catch(error) {
        //   console.log(error)
        // }

        setLoading(true)


        axios.put(`${process.env.REACT_APP_LOCAL_URL}/boutique/update_boutique/${BoutiqueData?.id_boutique}`, formData, { 
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)

                    if (response.data['errorList'].constructor == Array) {
                    seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'La boutique a été modifiée avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function() {
                        window.location = "/listboutiquesuser";
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }



    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];


    const DeleteImage = async (type) => {
        // setLoading(true);

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Êtes-vous sûr de vouloir supprimer cette Image ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/boutique/delete_boutique_img/id`,{"type" : type , "idbtq" : IdBoutique },{
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }
        
                    if (response['error'] == true) {
                        await Swal.fire(
                            'Deleted!',
                            "L'image n'a pas été supprimée avec succès",
                            'error'
                        );
                        
                    }
        
                    if (response['error'] == false) {
                        if (type === "logo") {
        
                            setPreview1(null)
                            setImage1(null)
                            setinitPreview1(false)
                            
                        }else if( type === "bg"){
                            setPreview2(null)
                            setImage2(null)
                            setinitPreview2(false)
                        }

                        await Swal.fire(
                            'Deleted!',
                            'La boutique a été supprimée avec succès',
                            'success'
                        );
                        
                        
                    }
                   
                    
                     
        
                
                } catch (error) {
                    console.error(error.message);
                }
              },
        })
        // .then(async (result) => {
        //     if (result.isConfirmed) {
        //         // await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/delete_admin/${id}`);

                
                
                

                
        //     } 
        // })

        
        // setLoading(false);
    }


    




    return (
        <div className='dashboard'>

            {Loading && 
            <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Modifier La Boutique <span>Store</span></div>
                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        <form className="row g-5">
                        <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (
                                    
                                    item.constructor == String ?
                                    <div className="alert-store" role="alert">
                                        - {item}
                                    </div>
                                    :''

                                ))
                                }

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom de boutique</label>
                                <input type="text" onChange={(e) => setNomdeboutique(e.target.value)} value={Nomdeboutique} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Nomdeboutique !== '' ? errors.Nomdeboutique : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Email</label>
                                <input type="email" onChange={(e) => setEmail(e.target.value)} value={Email} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Email !== '' ? errors.Email : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Adresse</label>
                                <input type="text" onChange={(e) => setAdresse(e.target.value)} value={Adresse} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Adresse !== '' ? errors.Adresse : ''}</div>
                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Tel</label>
                                <input type="tel" maxLength={12} onChange={(e) => setTel(e.target.value.replace(/[^0-9]+/g, ''))} value={Tel} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Tel !== '' ? errors.Tel : ''}</div>
                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Site</label>
                                <input type="text" onChange={(e) => setSite(e.target.value)} value={Site} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Site !== '' ? errors.Site : ''}</div>
                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Lien google Map</label>
                                <input type="text" onChange={(e) => setMap(e.target.value)} value={Map} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Map !== '' ? errors.Map : ''}</div>
                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Facebook Lien</label>
                                <input type="text" onChange={(e) => setFacebook(e.target.value)} value={Facebook} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Facebook !== '' ? errors.Facebook : ''}</div>
                            </div>


                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Instagram Lien</label>
                                <input type="text" onChange={(e) => setInstagram(e.target.value)} value={Instagram} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Instagram !== '' ? errors.Instagram : ''}</div>
                            </div>

                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Tel 2</label>
                                <input type="text" onChange={(e) => setTitreduproduit(e.target.value)} value={Titreduproduit} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Titreduproduit !== '' ? errors.Titreduproduit : ''}</div>
                            </div> */}

                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Tel 3</label>
                                <input type="text" onChange={(e) => setTitreduproduit(e.target.value)} value={Titreduproduit} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Titreduproduit !== '' ? errors.Titreduproduit : ''}</div>
                            </div> */}

                            <div class="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label for="inputState" class="form-label">Wilaya</label>
                                <select id="inputState" class="form-select" onChange={(e) => setWilaya(e.target.value)}>
                                <option disabled selected={Wilaya == "" ? "selected" : ''}>Wilaya</option>
                                    {WilayaList?.length > 0 && WilayaList?.map((item, i) => (
                                        <option selected={Wilaya == item?.value} value={item?.value}>{item?.text}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Wilaya !== '' ? errors.Wilaya : ''}</div>
                            </div>

                            <div className="col-md-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">Commune</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>
                                {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
                                <option disabled selected={Commune == "" ? "selected" : ''}>Commune</option>
                                {/* } */}


                                {CommuneList.length > 0 && CommuneList.map((item, i) => (
                                    <option selected={Commune == item?.commune} value={item?.commune}>{item?.commune}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Commune !== '' ? errors.Commune : ''}</div>

                        </div>


                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Heure de début</label>
                                <input type="time" className="form-control" onChange={(e) => setHeurededébut(e.target.value)} value={Heurededébut} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Heurededébut !== '' ? errors.Heurededébut : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Heure de fin</label>
                                <input type="time" className="form-control" onChange={(e) => setHeuredefin(e.target.value)} value={Heuredefin} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Heuredefin !== '' ? errors.Heuredefin : ''}</div>

                            </div>


                            {/* <div className="col-md-6 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Validé</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setValidé(e.target.checked)} checked={Validé} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Pub Accueil</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setTopAds(e.target.checked)} checked={TopAds} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div> */}




                            <div className="col-md-12">
                                <div className="form-floating">
                                    <textarea className="form-control" onChange={(e) => setDescription(e.target.value)} value={Description} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Description</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Description !== '' ? errors.Description : ''}</div>

                                </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-around flex-wrap">
                                <div className="mb-3">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label for="inputState" class="form-label">Logo</label>

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up mt-3'>
                                        {Image1 && <div className='up-close' onClick={() => initpreview1 ? DeleteImage('logo') : setImage1(null)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput1.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image1 &&
                                            <div onClick={() => { hiddenFileInput1.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview1} />

                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image1 !== '' ? errors.Image1 : ''}</div>

                                    <input ref={hiddenFileInput1} accept="image/png, image/jpg ,image/jpeg ,image/webp" className="form-control d-none" type="file" onChange={(e) => e.target.files.length > 0 ?  setImage1(e.target.files[0]) : setImage1()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label for="inputState" class="form-label">Background</label>

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up mt-3'>
                                        {Image2 && <div className='up-close' onClick={() =>  initpreview2 ? DeleteImage('bg') : setImage2(null)}>X</div>}

                                        <svg onClick={() => { hiddenFileInput2.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image2 &&
                                            <div onClick={() => { hiddenFileInput2.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview2} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image2 !== '' ? errors.Image2 : ''}</div>

                                    <input ref={hiddenFileInput2} accept="image/png, image/jpg ,image/jpeg ,image/webp" className="form-control d-none" type="file" onChange={(e) => e.target.files.length > 0 ? setImage2(e.target.files[0]) : setImage2()} id="formFile" />
                                </div>




                            </div>

                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Modifier</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpdateBoutiqueUser