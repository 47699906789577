import React from 'react'

const Banner = () => {
  return (
    <div className="store-home-banner mb-5"> 
    <div className="container"> 
        <div className="banner-content">
            <div className="banner-text">
                <p className="banner-h1">Vous aimeriez voir votre boutique ici ?</p>
                <p className="banner-desc">Créer votre boutique en ligne et vendez sur Diar Dzair</p>
            </div>
            <a href="/aide"> <div className="banner-btn mt-5 mt-lg-0">Commencer</div></a>
        </div>
        </div>
    </div>
  )
}

export default Banner