import { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BankContext from '../../Context/bank';
import Spinner from '../Global/Spinner';
// import LangContext from '../../Context/lang';



const Accueil = ({ Slug }) => {

    const { bankActive, setBankActive, GetTaksitPrice } = useContext(BankContext);
    //   const { Lang, setLang, NavMenu ,Global,ProductLang} = useContext(LangContext);

    const Lang = 'fr';

    const [Global, setGlobal] = useState({
        'Plus': { 'ar': 'شاهد المزيد', 'fr': 'Voir Plus' },
        'TopAnnonce': { 'fr': 'Top Annonce', 'ar': 'افضل المبيعات' },
        'Da': { 'fr': 'DA | Mois', 'ar': 'دج | للشهر' },
        'Dajust': { 'fr': 'DA', 'ar': 'دج' },
        'wilaya': { 'fr': 'Wilaya', 'ar': 'الولاية' },
        'minCartCadeaux': { 'fr': 'Montant minimum du panier pour bénéficier de ce cadeau', 'ar': 'الحد الأدنى لمبلغ السلة للاستفادة من هذه الهدية' },
        'latin': { 'fr': "Merci d'écrire en français", 'ar': 'الكتابة بالأحرف الاتينية' },
        'Number': { 'fr': "Veuillez écrire en chiffres", 'ar': 'الكتابة بالأرقام' },
    })


    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/categoriedz/`);
                // console.log(response[0]);
                setCategory(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);


    const [Ventes, setVentes] = useState([])
    const [Category, setCategory] = useState([])

    const [CategorySelected, setCategorySelected] = useState('')

    const [Loading, setLoading] = useState(true)

    console.log(Slug);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        
        const fetchData = async () => {
            const catcurrent = CategorySelected && CategorySelected !== 'all' ? '?cat=' + CategorySelected : '';
            try {
                const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/vente/ventes_valide/${Slug}${catcurrent !== '' ? catcurrent : ''}`);
                console.log(`${process.env.REACT_APP_LOCAL_URL}/vente/ventes_valide/${Slug}`);

                if (response['error'] == true) {

                    navigate('/')



                    // alert('hhhh')

                }
                setVentes(response['data']);
                // setCategory(response['categories'])
                // console.log(DarTaksitCat)

                console.log(response);

            } catch (error) {
                navigate('/')
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, [CategorySelected]);

    console.log(Ventes);
    console.log(Category);

    return (
        <div className="container mt-lg-5 pt-5 vh-100">
            <div className="row mt-lg-5">
                <div className="col-lg-3 mt-5">
                    <div className="search mb-5 d-none">
                        <i className="fa fa-search" />
                        <input type="text" className="form-control" placeholder="Rechercher..." />
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.012" height="22.006" viewBox="0 0 22.012 22.006"><path id="icons8-search" d="M22.81,14.4a8.421,8.421,0,1,0,5.95,2.46A8.389,8.389,0,0,0,22.81,14.4ZM22.8,15.708a6.992,6.992,0,0,1,5.008,2.083A7.064,7.064,0,1,1,22.8,15.708Zm0,1.33a5.632,5.632,0,0,0-4.056,1.706A5.756,5.756,0,0,0,17.059,22.6a.653.653,0,0,0,.643.687h.022a.678.678,0,0,0,.665-.643,4.5,4.5,0,0,1,1.285-2.97A4.4,4.4,0,0,1,22.8,18.367a.665.665,0,0,0,0-1.33Zm-3.834,8.355a.665.665,0,1,0,.665.665A.665.665,0,0,0,18.965,25.392Zm10.718,3.657a.616.616,0,0,0-.457.2.642.642,0,0,0,0,.931l.554.554a1.906,1.906,0,0,0-.2.864,1.994,1.994,0,0,0,.576,1.418l2.837,2.792a2,2,0,0,0,1.418.6,2.072,2.072,0,0,0,1.419-.576,1.993,1.993,0,0,0,0-2.814l-2.836-2.837a1.994,1.994,0,0,0-1.418-.576,1.906,1.906,0,0,0-.864.2l-.554-.554A.67.67,0,0,0,29.683,29.049Zm1.892,1.862a.6.6,0,0,1,.465.2l2.814,2.814a.658.658,0,0,1-.931.931L31.11,32.063a.687.687,0,0,1-.2-.487.6.6,0,0,1,.2-.465A.638.638,0,0,1,31.575,30.911Z" transform="translate(-14.4 -14.4)" fill="#fff" /></svg>
                        </button>
                    </div>
                    <div className="boutique-single-cat">
                        <h5>Catégories</h5>
                        <div className="boutique-single-cat-list mt-3">
                        <div className={`boutique-single-cat-items ${CategorySelected === "all" && 'boutique-single-cat-items-active'}`} onClick={() => setCategorySelected('all')}>{'Tous Les Catégories'}</div>

                            {Category?.length > 0 && Category?.map((item, i) => (
                                <div className={`boutique-single-cat-items ${CategorySelected === item?.id_cat && 'boutique-single-cat-items-active'}`} onClick={() => setCategorySelected(item?.id_cat)}>{item?.cat_name}</div>
                            ))
                            }
                            {/* <div className={`boutique-single-cat-items ${CategorySelected === 'hhh' && 'boutique-single-cat-items-active'}`} onClick={()=> setCategorySelected('hhh')}>hhhhhh</div> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="row mt-5">
                        {Loading ? <Spinner /> : ''} 
                        {!Loading && Ventes?.length > 0 && Ventes?.map((item, i) => (
                            <div className="col-md-6 col-lg-4 col-12">
                                <a href={`/product/${item?.boutique_slug}/${item?.vente_slug}`}>
                                    <div className="boutique-card-item">
                                        <img className="img-fluid" src={item?.image1 ? `${process.env.REACT_APP_LOCAL_URL}/images/ventes/${item?.image1}` : "https://opr.diardzair.com.dz/uploads/img/2661.jpeg"} />
                                        <div className="boutique-item-content">
                                            <div className="boutique-desc">
                                                <p className="boutique-item-title">{item?.titre_produit}</p>
                                                <p className="boutique-single-item-price">{(Lang === 'ar' ? <> <span className='me-1'> {(Global['Da'][Lang])} </span> {GetTaksitPrice(parseInt(item?.prix))['default']}</> : <>{GetTaksitPrice(parseInt(item?.prix))['default']}<span> {(Global['Da'][Lang])}</span></>)}</p>
                                            </div>
                                            <div className="boutique-item-taksit">
                                                تقسيط
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>


                        ))
                        }

                        {Ventes?.length == 0 && !Loading && <div className="text-center mt-5 mb-5">Pas de Produits</div>}

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Accueil